import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPairSlice {
  crypto: ICrypto;
  asset: IAsset;
}

export interface ICrypto {
  symbol: string;
  balance: string;
}

export interface IAsset {
  symbol: string;
  balance: string;
}

const INITIAL_STATE: IPairSlice = {
  crypto: {
    symbol: "B2U",
    balance: "0.00000000",
  },

  asset: {
    symbol: "BRLC",
    balance: "0.00",
  },
};

export const pairSlice = createSlice({
  name: "pair",
  initialState: INITIAL_STATE,
  reducers: {
    setCryptoSymbol: (state, action: PayloadAction<string>) => {
      state.crypto.symbol = action.payload;
      return state;
    },

    setCryptoBalance: (state, action: PayloadAction<string>) => {
      state.crypto.balance = action.payload;
      return state;
    },

    setAssetSymbol: (state, action: PayloadAction<string>) => {
      state.asset.symbol = action.payload;
      return state;
    },

    setAssetBalance: (state, action: PayloadAction<string>) => {
      state.asset.balance = action.payload;
      return state;
    },

    signOut: (state) => {
      state = INITIAL_STATE;
      return state;
    },
  },
});

export const {
  setCryptoSymbol,
  setCryptoBalance,
  setAssetSymbol,
  setAssetBalance,
  signOut,
} = pairSlice.actions;
export const pairReducer = pairSlice.reducer;
