import { useDispatch, useSelector } from "react-redux";
import {
  setAskBook,
  setBidBook,
  IBookData
} from "../state/features/Book/book.slice";
import { RootState } from "../state/store";

export const useBook = () => {
  const dispatch = useDispatch();

  const bidBook = useSelector(
    (state: RootState) => state.Book.bid
  );

  const askBook = useSelector(
    (state: RootState) => state.Book.ask
  );

  const onSetAskBook = (value: IBookData[]) => {
    dispatch(setAskBook(value));
  };

  const onSetBidBook = (value: IBookData[]) => {
    dispatch(setBidBook(value));
  };


  return {
    onSetAskBook,
    onSetBidBook,
    bidBook,
    askBook,
  };
};
