import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IBuySlice {
  limit: ILimitOrder;
  market: IMarketOrder;
  stop: IStopOrder;
}

export interface ILimitOrder {
  price: string;
  amount: string;
  totalOrder: string;
}

export interface IMarketOrder {
  price: string;
  amount: string;
  totalOrder: string;
}

export interface IStopOrder {
  stopPrice: string;
  limitPrice: string;
  amount: string;
  totalOrder: string;
}

const INITIAL_STATE: IBuySlice = {
  limit: {
    amount: "0",
    price: "0",
    totalOrder: "0",
  },

  market: {
    amount: "0",
    price: "0",
    totalOrder: "0",
  },

  stop: {
    stopPrice: "0",
    limitPrice: "0",
    amount: "0",
    totalOrder: "0",
  },
};

export const buySlice = createSlice({
  name: "buy",
  initialState: INITIAL_STATE,
  reducers: {
    setPriceLimitBuy: (state, action: PayloadAction<string>) => {
      state.limit.price = action.payload;
      return state;
    },

    setAmountLimitBuy: (state, action: PayloadAction<string>) => {
      state.limit.amount = action.payload;
      return state;
    },

    setTotalLimitBuy: (state, action: PayloadAction<string>) => {
      state.limit.totalOrder = action.payload;
      return state;
    },

    setPriceMarketBuy: (state, action: PayloadAction<string>) => {
      state.market.price = action.payload;
      return state;
    },

    setAmountMarketBuy: (state, action: PayloadAction<string>) => {
      state.market.amount = action.payload;
      return state;
    },

    setTotalMarketBuy: (state, action: PayloadAction<string>) => {
      state.market.totalOrder = action.payload;
      return state;
    },

    setStopPriceStopBuy: (state, action: PayloadAction<string>) => {
      state.stop.stopPrice = action.payload;
      return state;
    },

    setLimitPriceStopBuy: (state, action: PayloadAction<string>) => {
      state.stop.limitPrice = action.payload;
      return state;
    },

    setAmountStopBuy: (state, action: PayloadAction<string>) => {
      state.stop.amount = action.payload;
      return state;
    },

    setTotalStopBuy: (state, action: PayloadAction<string>) => {
      state.stop.totalOrder = action.payload;
      return state;
    },
    loggout: (state) => {
      state = INITIAL_STATE;
      return state;
    }
  },
});

export const {
  setPriceLimitBuy,
  setAmountLimitBuy,
  setTotalLimitBuy,
  setPriceMarketBuy,
  setAmountMarketBuy,
  setTotalMarketBuy,
  setStopPriceStopBuy,
  setLimitPriceStopBuy,
  setAmountStopBuy,
  setTotalStopBuy,
} = buySlice.actions;
export const buyReducer = buySlice.reducer;
