import React from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { IUserOrderData } from "../../state/features/UserOrder/userOrder.slice";
import {
  formatNumberLocale,
  formatNumberLocaleTrim,
  getPriceDecimalsPlaces,
} from "../../utils/stringUtils";

interface IOrderHistoryMobile {
  orders: IUserOrderData[];
  cancelItem: any;
  style?: string;
}

interface IOrderCard {
  order: IUserOrderData;
}

interface OrderData {
  crypto: string;
  asset: string;
  date: string;
  type: string;
  side: string;
  price: string;
  amount: string;
  pending: string;
  executed: string;
  value: string;
  stop?: string;
  status: string;
}

const OrderHistoryMobile = ({
  orders,
  cancelItem,
  style,
}: IOrderHistoryMobile) => {
  const { t } = useTranslation();

  const formatTime = (date: string | Date) => {
    let d = new Date(date);
    let day =
      d.getDate().toString().padStart(2, "0") +
      "/" +
      (d.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      d.getFullYear();
    let hours =
      d.getHours().toString().padStart(2, "0") +
      ":" +
      d.getMinutes().toString().padStart(2, "0") +
      ":" +
      d.getSeconds().toString().padStart(2, "0");
    return `${day} ${hours}`;
  };

  const OrderCard = ({ order }: IOrderCard) => {
    return (
      <div className={styles.card}>
        <div className={styles.ctRow}>
          <div className={styles.leftSide}>
            <p className={styles.pair}>{order.pair.replace("_", "/")}</p>
            <p
              className={
                order.side === "BUY" ? styles.typeBuy : styles.typeSell
              }>
              {order.side}
            </p>
          </div>

          {order.status === "EXECUTED" || order.status === "CANCELED" ? (
            <div className={styles.rightSide}>
              <p className={styles.status}>{order.status}</p>
            </div>
          ) : (
            <div className={styles.rightSide}>
              <FontAwesomeIcon
                icon={faTrashCan}
                size="xl"
                onClick={() => cancelItem(order.orderID, order.status)}
              />
            </div>
          )}
        </div>

        <div className={styles.ctRow}>
          <div className={styles.leftSide}>
            <p className={styles.label}>{t("pending") + " / " + t("amount")}</p>
          </div>

          <div className={styles.rightSide}>
            <p className={styles.info}>
              {formatNumberLocaleTrim(parseFloat(order.amountPending), 8) +
                " / " +
                formatNumberLocaleTrim(parseFloat(order.amount), 8)}
            </p>
          </div>
        </div>

        <div className={styles.ctRow}>
          <div className={styles.leftSide}>
            <p className={styles.label}>{t("executed")}</p>
          </div>

          <div className={styles.rightSide}>
            <p className={styles.info}>
              {formatNumberLocaleTrim(
                parseFloat(order.amountExecuted || "0"),
                8
              )}
            </p>
          </div>
        </div>

        {order.stopPrice ? (
          <div className={styles.ctRow}>
            <div className={styles.leftSide}>
              <p className={styles.label}>{t("stop")}</p>
            </div>

            <div className={styles.rightSide}>{order.stopPrice ? formatNumberLocale(order.stopPrice, getPriceDecimalsPlaces(order.pair)) : "-"}</div>
          </div>
        ) : null}

        <div className={styles.ctRow}>
          <div className={styles.leftSide}>
            <p className={styles.label}>{t("price")}</p>
          </div>

          <div className={styles.rightSide}>
            <p className={styles.info}>{formatNumberLocale(order.price, getPriceDecimalsPlaces(order.pair))}</p>
          </div>
        </div>

        <div className={styles.ctRow}>
          <div className={styles.leftSide}>
            <p className={styles.label}>{t("total")}</p>
          </div>

          <div className={styles.rightSide}>
            <p className={styles.info}>
              {formatNumberLocaleTrim(order.value, 2)}
            </p>
          </div>
        </div>

        <div className={styles.ctRow}>
          <div className={styles.leftSide}>
            <p className={styles.label}>{t("date")}</p>
          </div>

          <div className={styles.rightSide}>
            <p className={styles.info}>{formatTime(order.dateUpdated)}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.container} ${style}`}>
      {orders.map((entry: IUserOrderData) => {
        return <OrderCard order={entry} />;
      })}
    </div>
  );
};

export default OrderHistoryMobile;
