import axios from 'axios';
import ApiConfig from '../config/api-config';
import { stopWsEngine, unSubscribePair } from './wsEngine';
import { store } from "../state/store";

const getApiClientEngine = async () => {

  let token: string = store.getState().User.token;
  let fingerprint: string = store.getState().User.fingerprint;

  // if(!token) {
  // token = ApiConfig.tokenTeste;
  // token = store.getState().loginReducer.token;
  // fingerprint = store.getState().loginReducer.email == 'test1234cdf@gmail.com' ? 'device_id_ios_test' : store.getState().loginReducer.fingerprint;
  // }

  // if(!fingerprint) {
  //   fingerprint = await getUniqueId();
  // }

  const apiClientEngine = axios.create({
    baseURL: ApiConfig.CLIENT_ENGINE,
    responseType: 'json',
    withCredentials: false,
  });

  apiClientEngine.interceptors.request.use((config: any) => {
    if (token) {
      if (config.method !== 'OPTIONS') {
        config.headers.authorization = token;
        config.headers.fingerprint = fingerprint;
      }
    }
    return config;
  });

  await apiClientEngine.interceptors.response.use(
    (response) => {
      //console.log('NALISANDO ISSO TUDO    ASASASASS ', response.status);
      return response;
    },
    (error) => {
      try {
        console.log('NALISANDO ISSO TUDO    ASASASASS ', JSON.stringify(error));
        if (error.response.status === 401) {
          onLogOut();
        }

        return Promise.reject(error);
      } catch (err) {
        return Promise.reject(error);
      }

    }
  );

  return apiClientEngine;

}

const onLogOut = () => {
  let pair: string = store.getState().User.pair;
  console.log("PAIR ==> ", pair);
  unSubscribePair(pair);
  // store.dispatch(setShowLoading());
  // store.dispatch(logOut());
  stopWsEngine();

  setTimeout(async () => {
    // store.dispatch(setHideLoading());
    // store.dispatch(onClearStateRedux());

    // resetStore();
    // console.log("DADOS ==> ", getPesistor().getState());
  }, 5000);
};

export { getApiClientEngine };
