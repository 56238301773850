import React from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import BuyAndSellModal from "../Modal";
import { useInfo } from "../../../hooks/useInfo";

interface IBuyAndSellFixed {
  style?: string;
}

const BuyAndSellFixed = ({ style }: IBuyAndSellFixed) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = React.useState(false);

  const { onSetBuyOrSell } = useInfo();

  const openModal = (type: string) => {
    onSetBuyOrSell(type);
    setShowModal(true);
  };

  return (
    <>
      <BuyAndSellModal show={showModal} setShow={setShowModal} />

      <div
        className={showModal ? styles.hidden : `${styles.container} ${style}`}>
        <button className={styles.buyButton} onClick={() => openModal("buy")}>
          {t("buy")}
        </button>
        <button className={styles.sellButton} onClick={() => openModal("sell")}>
          {t("sell")}
        </button>
      </div>
    </>
  );
};

export default BuyAndSellFixed;
