import { useDispatch, useSelector } from "react-redux";
import {
  ISummarySlice,
  setSummary,
} from "../state/features/Summary/summary.slice";
import { RootState } from "../state/store";

export const useSummary = () => {
  const dispatch = useDispatch();

  const summary = useSelector((state: RootState) => state.Summary);

  const onSetSummary = (value: ISummarySlice) => {
    dispatch(setSummary(value));
  };

  return {
    summary,
    onSetSummary,
  };
};
