import React from "react";
import {
  formatNumberLocale,
  formatNumberLocaleTrim,
  getPriceDecimalsPlaces,
} from "../../utils/stringUtils";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { usePair } from "../../hooks/usePair";
import { trades } from "../../services/wsEngine";
import { BrowserView } from "react-device-detect";
import { ITradesData } from "../../state/features/Trade/trade.slice";
import { useTrade } from "../../hooks/useTrade";

interface ITrades {
  data: ITradesData[];
  style?: string;
}

interface TradesData {
  type: string;
  price: string;
  amount: string;
  time: string;
  dateUpdated: string;
}

const Trades = ({ data, style }: ITrades) => {
  const { t } = useTranslation();

  const { cryptoSymbol } = usePair();
  const { assetSymbol } = usePair();

  const { trades } = useTrade()
  data = trades;
  const formatTime = (date: string | Date) => {
    let d = new Date(date);
    return d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0') + ':' + d.getSeconds().toString().padStart(2, '0');
  }

  return (
    <div className={`${styles.container} ${style}`}>
      <BrowserView>
        <h3 className={styles.title}>{t("market trades")}</h3>
      </BrowserView>

      <div className={styles.ctTrades}>
        <div>
          <h3 className={styles.colLabelLeft}>
            {t("price")} ({assetSymbol})
          </h3>

          {data.map((entry) => {
            return (
              <div className={styles.ctList}>
                <p
                  className={
                    entry.side === "BUY"
                      ? styles.tradesItemBuy
                      : styles.tradesItemSell
                  }>
                  {formatNumberLocale(entry.price, getPriceDecimalsPlaces(cryptoSymbol + '_' + assetSymbol))}
                </p>
              </div>
            );
          })}
        </div>

        <div>
          <h3 className={styles.colLabelRight}>
            {t("amount")} ({cryptoSymbol})
          </h3>

          {data.map((entry) => {
            return (
              <div className={styles.ctList}>
                <p className={styles.tradesItemText}>
                  {formatNumberLocale(parseFloat(entry.amount), 8)}
                </p>
              </div>
            );
          })}
        </div>

        <div>
          <h3 className={styles.colLabelRight}>{t("time")}</h3>

          {data.map((entry) => {
            return (
              <div className={styles.ctList}>
                <p className={styles.tradesItemText}>{formatTime(entry.dateUpdated)}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Trades;
