import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITradeSlice {
  trade: ITradesData[];
}

export interface ITradesData {
  side: string;
  price: string;
  amount: string;
  time: string;
  dateUpdated: string;
}

const INITIAL_STATE: ITradeSlice = {
  trade: [],
};

export const tradeSlice = createSlice({
  name: "trade",
  initialState: INITIAL_STATE,
  reducers: {
    setTrade: (state, action: PayloadAction<ITradesData[]>) => {
      state.trade = action.payload;
      return state;
    },

    signOut: (state) => {
      state = INITIAL_STATE;
      return state;
    },
  },
});

export const { setTrade } = tradeSlice.actions;
export const tradeReducer = tradeSlice.reducer;
