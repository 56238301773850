import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserOrdersSlice {
  openOrders: IUserOrderData[];
  executedOrders: IUserOrderData[];
  tradeOrders: IUserOrderData[];
}

export interface IUserOrderData {
  status: string;
  orderID: string;
  price: string;
  amount: string;
  type: string;
  total: string;
  money: string;
  asset: string;
  pair: string;
  dateUpdated: string;
  side: string;
  amountPending: string;
  amountExecuted: string;
  value: string;
  stopPrice?: string;
  executedPrice: string;
  position: string;
  executedValue: string;
}

const INITIAL_STATE: IUserOrdersSlice = {
  openOrders: [],
  executedOrders: [],
  tradeOrders: []
};

export const userOrdersSlice = createSlice({
  name: "userorder",
  initialState: INITIAL_STATE,
  reducers: {
    setOpenOrders: (state, action: PayloadAction<IUserOrderData[]>) => {
      state.openOrders = action.payload;
      return state;
    },

    setExecutedOrders: (state, action: PayloadAction<IUserOrderData[]>) => {
      state.executedOrders = action.payload;
      return state;
    },
    setTradeOrders: (state, action: PayloadAction<IUserOrderData[]>) => {
      state.tradeOrders = action.payload;
      return state;
    },
    signOut: (state) => {
      state = INITIAL_STATE;
      return state;
    },
  },
});

export const { setOpenOrders, setExecutedOrders, setTradeOrders, signOut } =
  userOrdersSlice.actions;
export const userOrdersReducer = userOrdersSlice.reducer;
