import { useDispatch, useSelector } from "react-redux";
import { setAllSummary } from "../state/features/AllSummary/allsummary.slice";
import {
  ISummarySlice,
} from "../state/features/Summary/summary.slice";
import { RootState } from "../state/store";

export const useAllSummary = () => {
  const dispatch = useDispatch();

  const allSummary = useSelector((state: RootState) => state.AllSummary);

  const onSetAllSummary = (value: any) => {
    dispatch(setAllSummary(value));
  };

  return {
    allSummary,
    onSetAllSummary,
  };
};
