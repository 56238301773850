import { createSlice } from "@reduxjs/toolkit";
export interface ILoadingSlice {
  show: boolean;
}

const INITIAL_STATE: ILoadingSlice = {
  show: false,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState: INITIAL_STATE,
  reducers: {
    showLoading: (state) => {
      return {
        ...state,
        show: true,
      };
    },
    hideLoading: (state) => {
      return {
        ...state,
        show: false,
      };
    },
  },
});

export const { showLoading, hideLoading } = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;
