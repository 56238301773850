import React from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import {
  formatNumberLocale,
  formatNumberLocaleTrim,
} from "../../utils/stringUtils";
import { IUserOrderData } from "../../state/features/UserOrder/userOrder.slice";

interface ITradeHistoryMobile {
  orders: IUserOrderData[];
  style?: string;
}

interface IOrderCard {
  order: IUserOrderData;
}

const TradeHistoryMobile = ({ orders, style }: ITradeHistoryMobile) => {
  const { t } = useTranslation();

  const formatTime = (date: string | Date) => {
    let d = new Date(date);
    let day =
      d.getDate().toString().padStart(2, "0") +
      "/" +
      (d.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      d.getFullYear();
    let hours =
      d.getHours().toString().padStart(2, "0") +
      ":" +
      d.getMinutes().toString().padStart(2, "0") +
      ":" +
      d.getSeconds().toString().padStart(2, "0");
    return `${day} ${hours}`;
  };

  const OrderCard = ({ order }: IOrderCard) => {
    return (
      <div className={styles.card}>
        <div className={styles.ctRow}>
          <div className={styles.leftSide}>
            <p className={styles.pair}>{order.pair.replace("_", "/")}</p>
            <p
              className={
                order.side === "BUY" ? styles.typeBuy : styles.typeSell
              }>
              {order.side}
            </p>
          </div>

          <div className={styles.rightSide}>
            <p className={styles.function}>{/*entry.function*/ order.position}</p>
          </div>
        </div>

        <div className={styles.ctRow}>
          <div className={styles.leftSide}>
            <p className={styles.label}>{t("executed")}</p>
          </div>

          <div className={styles.rightSide}>
            <p className={styles.info}>
              {formatNumberLocaleTrim(
                parseFloat(order.amountExecuted || "0"),
                8
              )}
            </p>
          </div>
        </div>

        <div className={styles.ctRow}>
          <div className={styles.leftSide}>
            <p className={styles.label}>{t("price")}</p>
          </div>

          <div className={styles.rightSide}>
            <p className={styles.info}>{formatNumberLocale(order.price, 2)}</p>
          </div>
        </div>

        <div className={styles.ctRow}>
          <div className={styles.leftSide}>
            <p className={styles.label}>{t("total")}</p>
          </div>

          <div className={styles.rightSide}>
            <p className={styles.info}>
              {formatNumberLocaleTrim(order.executedValue, 2)}
            </p>
          </div>
        </div>

        <div className={styles.ctRow}>
          <div className={styles.leftSide}>
            <p className={styles.label}>{t("date")}</p>
          </div>

          <div className={styles.rightSide}>
            <p className={styles.info}>{formatTime(order.dateUpdated)}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.container} ${style}`}>
      {orders.map((entry: IUserOrderData) => {
        return <OrderCard order={entry} />;
      })}
    </div>
  );
};

export default TradeHistoryMobile;
