import React from "react";
import styles from "./styles.module.css";
import Header from "./components/Header";
import PairInfoBox from "./components/PairInfoBox";
import PairSearchBox from "./components/PairSearchBox";
import Trades from "./components/Trades";
import OrderBook from "./components/OrderBook";
import PairGraph from "./components/PairGraph";
import BuyAndSell from "./components/BuyAndSell";
import HistoryTabs from "./components/HistoryTabs";
import i18n from "./translate/i18n";
import { Provider } from "react-redux";
import { persistor, store } from "./state/store";
import { PersistGate } from "redux-persist/integration/react";
import { IUserSlice, setAll } from "./state/features/User/user.slice";
import { initWsEngine } from "./services/wsEngine";
import PairInfoBoxMobile from "./components/PairInfoBoxMobile";
import { BrowserView, MobileView } from "react-device-detect";
import MobileTabs from "./components/MobileTabs";
import BuyAndSellFixed from "./components/BuyAndSell/FixedButton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useBook } from "./hooks/useBook";
import { initWsChart } from "./services/wsChart";

function App() {
  // const { onSetAll } = useUser();
  // const {askBook, bidBook} = useBook()
  i18n.init();

  React.useEffect(() => {
    const queryString = window.location.search;
    console.log(queryString);
    const searchParams = new URLSearchParams(queryString);
    console.log(searchParams);
    let userData: IUserSlice = {
      token: searchParams.get("token") || store.getState().User.token,
      fingerprint:
        searchParams.get("fingerDevice") || store.getState().User.fingerprint,
      pair: store.getState().User.pair,
      subID: searchParams.get("subID") || store.getState().User.subID,
      sub: searchParams.get("sub") || store.getState().User.sub,
      userID: searchParams.get("userID") || store.getState().User.userID,
      email: searchParams.get("email") || store.getState().User.email,
      idConnection: "",
      idConnectionChart: "",
    };
    store.dispatch(setAll(userData));
    initWsEngine();
    initWsChart();
  }, []);

  // var buyBookData = bidBook;
  // var sellBookData = askBook;
  // const buyBookData = [
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  // ];

  // const sellBookData = [
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  //   {
  //     type: "sell",
  //     price: "160.24",
  //     amount: "0.150",
  //     total: "23.48",
  //   },
  // ];

  const tradesData = store.getState().Trade.trade; //[
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  //   { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  // ];

  return (
    <Provider store={store}>
      <ToastContainer />
      <PersistGate loading={null} persistor={persistor} />
      <BrowserView>
        <div className={styles.container}>
          <div className={styles.content}>
            <Header />
            <div className={styles.ctMiddle}>
              <div className={styles.ctMiddleLeft}>
                <PairInfoBox
                  value={tradesData[0] ? tradesData[0].price : ""}
                  type={tradesData[0] ? tradesData[0].side : ""}
                />

                <div className={styles.ctMiddleLeftCenter}>
                  <div className={styles.ctMiddleLeftCenterLeft}>
                    <OrderBook
                      buyData={store.getState().Book.bid}
                      sellData={store.getState().Book.ask}
                    />
                  </div>

                  <div className={styles.ctMiddleLeftCenterRight}>
                    <PairGraph />
                    <BuyAndSell />
                  </div>
                </div>
              </div>

              <div className={styles.ctMiddleRight}>
                <PairSearchBox />
                <Trades data={store.getState().Trade.trade} />
              </div>
            </div>

            <div className={styles.ctBottom}>
              <HistoryTabs />
            </div>
          </div>
        </div>
      </BrowserView>

      <MobileView>
        <div className={styles.container}>
          <div className={styles.content}>
            <Header />

            <PairInfoBoxMobile
              value={tradesData[0] ? tradesData[0].price : "0"}
              type={tradesData[0] ? tradesData[0].side : "0"}
            />

            <div className={styles.midTabs}>
              <MobileTabs />
            </div>

            <div className={styles.bottomTabs}>
              <HistoryTabs />
            </div>
          </div>
          <BuyAndSellFixed />
        </div>
      </MobileView>
    </Provider>
  );
}

export default App;
