import React from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import {
  formatNumberLocale,
  formatNumberLocaleTrim,
  getPriceDecimalsPlaces,
} from "../../utils/stringUtils";
import { IUserOrderData } from "../../state/features/UserOrder/userOrder.slice";

interface ITradeHistory {
  orders: IUserOrderData[];
  style?: string;
}

const TradeHistory = ({ orders, style }: ITradeHistory) => {
  const { t } = useTranslation();

  const formatTime = (date: string | Date) => {
    let d = new Date(date);
    let day =
      d.getDate().toString().padStart(2, "0") +
      "/" +
      (d.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      d.getFullYear();
    let hours =
      d.getHours().toString().padStart(2, "0") +
      ":" +
      d.getMinutes().toString().padStart(2, "0") +
      ":" +
      d.getSeconds().toString().padStart(2, "0");
    return `${day} ${hours}`;
  };

  return (
    <div className={`${styles.container} ${style}`}>
      <div className={styles.ctCol}>
        <h6 className={styles.labelLeft}>{t("date")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemLeft}>{formatTime(entry.dateUpdated)}</p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("pair")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemRight}>{entry.pair.replace("_", "/")}</p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("side")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p
                className={
                  entry.side === "BUY" ? styles.buySide : styles.sellSide
                }>
                {t(entry.side)}
              </p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("price")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemRight}>
                {formatNumberLocale(entry.price, getPriceDecimalsPlaces(entry.pair))}
              </p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("executed")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemRight}>
                {formatNumberLocaleTrim(
                  parseFloat(entry.amountExecuted || "0"),
                  8
                )}
              </p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("function")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemRight}>
                {/*entry.function*/ entry.position}
              </p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.lastLabel}>{t("total")}</h6>

        <div className={styles.ctLastItem}>
          {orders.map((entry) => {
            return (
              <p className={styles.lastItem}>
                {formatNumberLocaleTrim(entry.executedValue, getPriceDecimalsPlaces(entry.pair))}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TradeHistory;
