import React from "react";
import styles from "./styles.module.css";

interface IInputBar {
  prefix: string;
  suffix: string;
  input: string;
  setInput?: any;
  disabled?: boolean;
  style?: string;
}

const InputBar = ({
  prefix,
  suffix,
  input,
  setInput,
  disabled,
  style,
}: IInputBar) => {
  return (
    <div className={`${styles.container} ${style}`}>
      <p className={styles.inputPrefix}>{prefix}</p>

      <div className={styles.ctInputEnd}>
        <input
          className={styles.inputField}
          value={input}
          disabled={disabled}
          onChange={(event) => {
            setInput(event.target.value);
          }}
        />

        <p className={styles.inputSuffix}>{suffix}</p>
      </div>
    </div>
  );
};

export default InputBar;
