import React from "react";
import {
  formatNumberLocale,
  formatNumberLocaleTrim,
} from "../../../utils/stringUtils";
import InputBar from "../../InputBar";
import OrderValuePreset from "../../OrderValuePreset";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { usePair } from "../../../hooks/usePair";
import { useBuy } from "../../../hooks/useBuy";
import { useSell } from "../../../hooks/useSell";
import { toast } from "react-toastify";
import { createOrder, ICreateOrderBody } from "../../../services/advanced";

interface ILimit {
  buyOrSell?: string;
  style?: string;
}

const Limit = ({ buyOrSell, style }: ILimit) => {
  const { t } = useTranslation();

  const formatTo = (value: string, limit: number) => {
    value = value.replaceAll(",", ".");
    let i = value.lastIndexOf(".");

    if (i > 0) {
      let integer = value.substring(0, i).replaceAll(".", "");
      let decimals = value.substring(i + 1, value.length);
      decimals = decimals.substring(0, limit);
      value = integer + "." + decimals;
    }

    return value;
  };

  const { cryptoSymbol } = usePair();
  const { cryptoBalance } = usePair();
  const { assetSymbol } = usePair();
  const { assetBalance } = usePair();

  const [nZero, setNZero] = React.useState(
    cryptoSymbol === "BABYDOGE" ? 12 : 8
  );

  React.useEffect(() => {
    setNZero(cryptoSymbol === "BABYDOGE" ? 12 : 8);
  }, [cryptoSymbol]);

  const { buyLimitPrice, onSetPriceLimitBuy } = useBuy();
  const { buyLimitAmount, onSetAmountLimitBuy } = useBuy();
  const { buyLimitTotal, onSetTotalLimitBuy } = useBuy();

  const { sellLimitPrice, onSetPriceLimitSell } = useSell();
  const { sellLimitAmount, onSetAmountLimitSell } = useSell();
  const { sellLimitTotal, onSetTotalLimitSell } = useSell();

  React.useEffect(() => {
    onSetTotalLimitBuy(
      (
        parseFloat(formatTo(buyLimitPrice, nZero)) *
        parseFloat(formatTo(buyLimitAmount, nZero))
      )
        .toFixed(2)
        .toString()
    );
  }, [onSetTotalLimitBuy, buyLimitPrice, buyLimitAmount, nZero]);

  React.useEffect(() => {
    onSetTotalLimitSell(
      (
        parseFloat(formatTo(sellLimitPrice, nZero)) *
        parseFloat(formatTo(sellLimitAmount, nZero))
      )
        .toFixed(2)
        .toString()
    );
  }, [onSetTotalLimitSell, sellLimitPrice, sellLimitAmount, nZero]);

  const buyMsg = () => (
    <div className={styles.ctMsg}>
      <p className={styles.msgTitle}>{t("new buy limit order")}</p>
      <p className={styles.msgBody}>
        {t("sent buy limit order")} {buyLimitAmount} {cryptoSymbol} {t("using")}{" "}
        {assetSymbol}
      </p>
    </div>
  );

  const sellMsg = () => (
    <div className={styles.ctMsg}>
      <p className={styles.msgTitle}>{t("new sell limit order")}</p>
      <p className={styles.msgBody}>
        {t("sent sell limit order")} {sellLimitAmount} {cryptoSymbol}{" "}
        {t("using")} {assetSymbol}
      </p>
    </div>
  );

  const notify = (msg: any) => {
    toast.success(msg, {
      style: {
        backgroundColor: "rgb(43, 49, 57)",
      },
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const successHandler = (res: any, type: string) => {
    let data = res.data.data;
    if (data.message) data = data.message;
    console.log(data);
    if (data === "Insuficient balance") {
      notify(t("insufficient balance"));
    } else if (data && data.split('#')[0] === "Invalid b2u price") {
      notify(t("b2u 100k limite").toString() + ' ' + data.split('#')[1]);
    } else if (data && data.split('#')[0] === "Invalid b2u amount") {
      notify(t("b2u 100k limite").toString() + ' ' + data.split('#')[1]);
    } else if (data === "Invalid amount") {
      notify(t("invalid value"));
    } else if (data === "Amount invalid") {
      notify(t("invalid value"));
    } else if (data === "Invalid price") {
      notify(t("invalid price 30"));
    } else if (
      data === "Transação não realizada, tente novamente em alguns segundos"
    ) {
      notify(t("transaction not made"));
    } else if (data === "Total value must be superior to 10BRL") {
      notify(t("superior 10BRL"));
    } else {
      notify(type === "buy" ? buyMsg() : sellMsg());
    }
  };

  const errorHandler = (err: any) => {
    let data = err.response.data
      ? err.response.data.data
        ? err.response.data.data.message
          ? err.response.data.data.message
          : err.response.data.data
        : ""
      : "";
    console.log(data);
    if (data === "Insuficient balance") {
      notify(t("insufficient balance"));
    } else if (data && data.split('#')[0] === "Invalid b2u price") {
      notify(t("b2u 100k limite").toString() + ' ' + data.split('#')[1]);
    } else if (data && data.split('#')[0] === "Invalid b2u amount") {
      notify(t("b2u 100k limite").toString() + ' ' + data.split('#')[1]);
    } else if (data === "Invalid amount") {
      notify(t("invalid value"));
    } else if (data === "Amount invalid") {
      notify(t("invalid value"));
    } else if (data === "Invalid price") {
      notify(t("invalid price"));
    }  else if (data === "Invalid above 30 variation") {
      notify(t("invalid price 30"));
    } else if (
      data === "Transação não realizada, tente novamente em alguns segundos"
    ) {
      notify(t("transaction not made"));
    } else if (data === "Total value must be superior to 10BRL") {
      notify(t("superior 10BRL"));
    }
  };

  const confirmBuy = () => {
    let body: ICreateOrderBody = {
      pair: cryptoSymbol + "_" + assetSymbol,
      amount: buyLimitAmount,
      side: "BUY",
      price: buyLimitPrice,
    };
    createOrder(body)
      .then((res) => {
        successHandler(res, "buy");
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const confirmSell = () => {
    let body: ICreateOrderBody = {
      pair: cryptoSymbol + "_" + assetSymbol,
      amount: sellLimitAmount,
      side: "SELL",
      price: sellLimitPrice,
    };
    createOrder(body)
      .then((res) => {
        successHandler(res, "sell");
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  return (
    <div className={`${styles.container} ${style}`}>
      <div
        className={
          buyOrSell === "sell" ? styles.ctInputsHide : styles.ctInputsShow
        }>
        <div className={styles.ctBalance}>
          <p className={styles.balanceText}>{t("available")}</p>
          <p className={styles.balanceValue}>
            {formatNumberLocale(assetBalance, 2) + " " + assetSymbol}
          </p>
        </div>

        <InputBar
          prefix={t("price")}
          suffix={assetSymbol}
          input={buyLimitPrice}
          setInput={onSetPriceLimitBuy}
          style={styles.inputField}
        />

        <InputBar
          prefix={t("amount")}
          suffix={cryptoSymbol}
          input={buyLimitAmount}
          setInput={onSetAmountLimitBuy}
          style={styles.inputField}
        />

        <OrderValuePreset
          price={buyLimitPrice}
          setAmount={onSetAmountLimitBuy}
          wallet={assetBalance}
          buyOrSell="buy"
          style={styles.presetField}
        />

        <InputBar
          prefix={t("total")}
          suffix={assetSymbol}
          input={buyLimitTotal}
          disabled={true}
          style={styles.inputField}
        />

        <button className={styles.confirmBuy} onClick={() => confirmBuy()}>
          {t("buy")} {cryptoSymbol}
        </button>
      </div>

      <div
        className={
          buyOrSell === "buy" ? styles.ctInputsHide : styles.ctInputsShow
        }>
        <div className={styles.ctBalance}>
          <p className={styles.balanceText}>{t("available")}</p>
          <p className={styles.balanceValue}>
            {formatNumberLocaleTrim(cryptoBalance, 8) + " " + cryptoSymbol}
          </p>
        </div>

        <InputBar
          prefix={t("price")}
          suffix={assetSymbol}
          input={sellLimitPrice}
          setInput={onSetPriceLimitSell}
          style={styles.inputField}
        />

        <InputBar
          prefix={t("amount")}
          suffix={cryptoSymbol}
          input={sellLimitAmount}
          setInput={onSetAmountLimitSell}
          style={styles.inputField}
        />

        <OrderValuePreset
          price={sellLimitPrice}
          setAmount={onSetAmountLimitSell}
          wallet={cryptoBalance}
          buyOrSell="sell"
          style={styles.presetField}
        />

        <InputBar
          prefix={t("total")}
          suffix={assetSymbol}
          input={sellLimitTotal}
          disabled={true}
          style={styles.inputField}
        />

        <button className={styles.confirmSell} onClick={() => confirmSell()}>
          {t("sell")} {cryptoSymbol}
        </button>
      </div>
    </div>
  );
};

export default Limit;
