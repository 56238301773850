import React from "react";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { useUser } from "../../hooks/useUser";
import { TVChartContainer } from "../TVChartContainer";
import styles from "./styles.module.css";

interface IPairGraph {
  style?: string;
}

const PairGraph = ({ style }: IPairGraph) => {
  const { pair } = useUser();
  return (
    <div className={`${styles.container} ${style}`}>
      <TVChartContainer symbol={pair} />
    </div>
  );
};

export default PairGraph;
