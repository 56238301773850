import { getApiClientCS } from './clientCS';
import { getApiClientEngine } from './clientEngine';
import { getApiClientChart } from './clientChart';
// import { IMarketBody } from './orders';

interface ICommonBody {
    pair: string,
    idConnection: string
}

export interface ICreateOrderBody {
    pair: string,
    amount: string,
    side: string,
    price: string,
    stop?: string
}

export interface IMarketBody {
    pair: string,
    amount: string,
    side: string
}

export const notifyTrades = async (body: ICommonBody) => {
    let apiClientEngine = await getApiClientEngine();
    return apiClientEngine.post(`/order/notifyTrades`, body);
}

export const notifyMarketSummary = async (body: ICommonBody) => {
    let apiClientChart = await getApiClientChart();
    console.log("notify MarketSummary");
    return apiClientChart.post(`/order/notifyMarketSummary`, body);
}

export const notifyAllSummary = async (body: ICommonBody) => {
    let apiClientChart = await getApiClientChart();
    console.log("notify MarketSummary");
    return apiClientChart.post(`/order/notifyAllSummary`, body);
}

export const notifyCandleChart = async (body: ICommonBody) => {
    let apiClientChart = await getApiClientChart();
    console.log("notify CandleChart");
    return apiClientChart.post(`/order/notifyCandleChart`, body);
}

export const notifyUserOrder = async (body: ICommonBody) => {
    let apiClientEngine = await getApiClientEngine();
    return apiClientEngine.post(`/order/notifyUserOrderListByPair`, body);
}

export const notifyAskOrderBook = async (body: ICommonBody) => {
    let apiClientEngine = await getApiClientEngine();
    return apiClientEngine.post(`/order/v2/notifyAskOrderBook`, body);
}

export const notifyBidOrderBook = async (body: ICommonBody) => {
    let apiClientEngine = await getApiClientEngine();
    return apiClientEngine.post(`/order/v2/notifyBidOrderBook`, body);
}

export const createOrder = async (body: ICreateOrderBody) => {
    let apiClientEngine = await getApiClientEngine();
    return apiClientEngine.post(`/order/create`, body);
}

export const createStop = async (body: ICreateOrderBody) => {
    let apiClientEngine = await getApiClientEngine();
    return apiClientEngine.post(`/order/stop`, body);
}

export const marketOrder = async (body: IMarketBody) => {
    let apiClientEngine = await getApiClientEngine();
    return apiClientEngine.post(`/order/market`, body);
}

export const cancelOrder = async (orderID: string) => {
    let apiClientEngine = await getApiClientEngine();
    return apiClientEngine.post(`/order/cancel`, { orderID });
}

export const cancelAllOrder = async (pair: string) => {
    let apiClientEngine = await getApiClientEngine();
    return apiClientEngine.post(`/order/cancellAll`, { pair });
}

export const cancelOrderStop = async (orderID: string) => {
    let apiClientEngine = await getApiClientEngine();
    return apiClientEngine.post(`/order/cancelStop`, { orderID });
}