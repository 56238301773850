import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IBookSlice {
  ask: IBookData[];
  bid: IBookData[];
}

export interface IBookData {
  price: string | number;
  amount: string | number;
  amountWithFormatter?: string;
  type: string;
  total: string;
}

const INITIAL_STATE: IBookSlice = {
  ask: [],
  bid: []
};

export const bookSlice = createSlice({
  name: "book",
  initialState: INITIAL_STATE,
  reducers: {
    setAskBook: (state, action: PayloadAction<IBookData[]>) => {
      state.ask = action.payload;
      return state;
    },

    setBidBook: (state, action: PayloadAction<IBookData[]>) => {
      state.bid = action.payload;
      return state;
    },
    loggout: (state) => {
      state = INITIAL_STATE;
      return state;
    }
  },
});

export const {
  setAskBook,
  setBidBook
} = bookSlice.actions;
export const bookReducer = bookSlice.reducer;
