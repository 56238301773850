import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserSlice {
  token: string;
  fingerprint: string;
  pair: string;
  subID: string;
  userID: string;
  email: string;
  sub: string;
  idConnection: string;
  idConnectionChart: string;
}

const INITIAL_STATE: IUserSlice = {
  token: "",
  fingerprint: "",
  pair: "B2U_BRLC",
  subID: "0",
  sub: "main",
  userID: "",
  email: "",
  idConnection: "",
  idConnectionChart: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    setFingerprint: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      return state;
    },

    setToken: (state, action: PayloadAction<string>) => {
      state.fingerprint = action.payload;
      return state;
    },

    setIdConnection: (state, action: PayloadAction<string>) => {
      state.idConnection = action.payload;
      return state;
    },

    setIdConnectionChart: (state, action: PayloadAction<string>) => {
      state.idConnectionChart = action.payload;
      return state;
    },

    setPair: (state, action: PayloadAction<string>) => {
      state.pair = action.payload;
      return state;
    },

    setAll: (state, action: PayloadAction<IUserSlice>) => {
      state = action.payload;
      return state;
    },

    signOut: (state) => {
      state = INITIAL_STATE;
      return state;
    },
  },
});

export const {
  setFingerprint,
  setToken,
  setPair,
  setIdConnection,
  setIdConnectionChart,
  setAll,
  signOut,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
