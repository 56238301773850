import React from "react";
import styles from "./styles.module.css";
import logo from "../../assets/logo-white.png";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import SideMenu from "../SideMenu";
import { useInfo } from "../../hooks/useInfo";
import { BrowserView, MobileView } from "react-device-detect";
import ApiConfig from "../../config/api-config";
import { useUser } from "../../hooks/useUser";

interface IHeader {
  style?: string;
}

const Header = ({ style }: IHeader) => {
  const { t } = useTranslation();

  const { token, onSignOut } = useUser();
  const { isLoggedIn, onSetIsLoggedIn } = useInfo();

  React.useMemo(() => {
    token === "" ? onSetIsLoggedIn(false) : onSetIsLoggedIn(true);
  }, [token, onSetIsLoggedIn]);

  const signIn = () => {
    window.location.assign(`${ApiConfig.PORTAL_URL}/sign-in?origem=PRO`);
  };

  const signUp = () => {
    window.location.assign(`${ApiConfig.PORTAL_URL}/afiliado/`);
  };

  const signOut = () => {
    onSignOut();
  };

  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <SideMenu show={showModal} setShow={setShowModal} />

      <div className={`${styles.container} ${style}`}>
        <div className={styles.ctLogo}>
          <img className={styles.logo} src={logo} alt="Logo" />
        </div>

        {isLoggedIn ? (
          <>
            <BrowserView>
              <div className={styles.ctMenu}>
                <a className={styles.menuButton} href={ApiConfig.PORTAL_URL}>
                  {t("home")}
                </a>
                <a
                  className={styles.menuButton}
                  href={`${ApiConfig.PORTAL_URL}/depositar`}>
                  {t("deposit")}
                </a>
                <a
                  className={styles.menuButton}
                  href={`${ApiConfig.PORTAL_URL}/transferir`}>
                  {t("withdraw")}
                </a>
                <a
                  className={styles.menuButton}
                  href={`${ApiConfig.PORTAL_URL}/extrato`}>
                  {t("extract")}
                </a>
                <a
                  className={styles.menuButton}
                  href={`${ApiConfig.PORTAL_URL}/subaccounts`}>
                  {t("sub acc")}
                </a>
                <a
                  className={styles.menuButton}
                  href={`${ApiConfig.PORTAL_URL}/investir`}>
                  {t("swap")}
                </a>
                <p className={styles.menuButton} onClick={() => signOut()}>
                  {t("sign out")}
                </p>
              </div>
            </BrowserView>

            <MobileView>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faBars}
                size="2x"
                onClick={() => setShowModal(true)}
              />
            </MobileView>
          </>
        ) : (
          <div className={styles.ctLogin}>
            <button className={styles.signIn} onClick={() => signIn()}>
              {t("sign in")}
            </button>
            <button className={styles.signUp} onClick={() => signUp()}>
              {t("sign up")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
