
import { getApiClientEngine } from "./clientEngine";
import ApiConfig from '../config/api-config';
import { notifyAllSummary, notifyAskOrderBook, notifyBidOrderBook, notifyCandleChart, notifyMarketSummary, notifyTrades, notifyUserOrder } from "./advanced";
import { useUser } from "../hooks/useUser";

// let idConnection: string | null;
// const { token, fingerprint, pair, idConnectionChart, onSetIdConnectionChart } = useUser();
import { store } from "../state/store";
import { setIdConnectionChart } from "../state/features/User/user.slice";
import { setSummary } from "../state/features/Summary/summary.slice";
import { Observable, Subject } from "rxjs";
import { setAllSummary } from "../state/features/AllSummary/allsummary.slice";
// export var summary: ISummary = {
//   first: '0',
//   last: '0',
//   high: '0',
//   low: '0',
//   variation: '0%',
//   amount: '0',
//   quote_volume: '0'
// };

export let socketGlobal: WebSocket;
let interval: any = null;


interface IMessageInitWs {
  method: string;
  pair: string;
  token?: string;
  origem: string;
  fingerprint?: string;
}

const candleChartDataSource = new Subject<any>();
export const candleChartData$ = candleChartDataSource.asObservable();

export const createWs = (url: string) => {
  let socket = new WebSocket(url);
  return socket;
};

export const initWsChart = async () => {
  let token: string = store.getState().User.token;
  let fingerprint: string = store.getState().User.fingerprint;
  let pair: string = store.getState().User.pair;

  // let token: string = ApiConfig.tokenTeste;
  // let pair: string = "BTC_BRLC";

  if (token == null || pair == null) {
    return;
  }
  socketGlobal = createWs(ApiConfig.PUBLIC_CHART_WEBSOCKET);

  socketGlobal.onmessage = (ev: any) => {
    if (ev && ev.type == "message") {
      processWsMessage(ev.data);
    }
  };

  socketGlobal.onclose = (err) => {
    // let allReducers: IAllReducers = store.getState() as IAllReducers;
    setTimeout(() => {
      // if (allReducers.loginReducer.isLoggedIn) {
      initWsChart();
      // }
    }, 10000);
  };

  socketGlobal.onopen = () => {
    console.log("OPEN WS CHART!!!");

    let messageObj: IMessageInitWs = {
      method: "pairSubscribe",
      pair: pair,
      origem: "APP",
      token: token,
      fingerprint,
    };

    let summary = {
      first: '0',
      last: '0.00',
      high: '0',
      low: '0',
      variation: '0%',
      variationAmount: '0',
      amount: '0',
      quote_volume: '0',
      pair: ''
    }
    store.dispatch(setSummary(summary));

    if (socketGlobal && socketGlobal.readyState == 1) {
      socketGlobal.send(JSON.stringify(messageObj));
    }
    interval = setInterval(() => {
      if (socketGlobal && socketGlobal.readyState == 1) {
        socketGlobal.send(JSON.stringify({ ping: "." }));
      }
    }, 30000);
  };
};

export const stopWsChart = () => {
  if (socketGlobal) {
    socketGlobal.close();
    clearInterval(interval);
  }

};

export const loadAdvanced = async () => {
  let idConnectionChart: string = store.getState().User.idConnectionChart;
  let pair: string = store.getState().User.pair;

  let body = {
    pair: pair,
    idConnection: idConnectionChart,
    connectionID: idConnectionChart
  }
  console.log(body);
  // notifyTrades(body);
  await notifyAllSummary(body);
  await notifyMarketSummary(body);
  await notifyCandleChart(body);
}

export const notifyChart = async (pair: string, interval: string) => {
  let idConnectionChart: string = store.getState().User.idConnectionChart;

  let body = {
    pair: pair,
    idConnection: idConnectionChart,
    connectionID: idConnectionChart,
    interval: interval || 1440
  }
  console.log(body);
  // notifyTrades(body);
  await notifyCandleChart(body);
}

const processWsMessage = (ev: any) => {
  // let pair: string = store.getState().loginReducer.pair;
  let temp = null;
  try {
    temp = JSON.parse(ev);
  } catch (error) {
    return;
  }

  // console.log(`chart`, temp)
  if (temp.connectionID) {

    if (temp.status == "200") {
      store.dispatch(setIdConnectionChart(temp.connectionID));
      if (temp.text == "subscribe with sucess")
        loadAdvanced();
      // onSetIdConnectionChart(temp.connectionID);
    }
  }
  switch (temp.method) {
    case 'allSummary':
      setAllMarketSummaryData(temp);
      break;
    case 'summary':
      setMarketSummaryData(temp.list);
      break;
    case 'candleChart':
      setChartData(temp.list);
      break;
  }
};

function setChartData(data: any[]) {
  // let date = new Date();
  // date.setDate(date.getDate() - 1);
  candleChartDataSource.next(data);
  // let chart = data.slice(-100).map(item => Number(item.price));
  // let summary = {
  //   first: data.first,
  //   last: data.last,
  //   high: data.high,
  //   low: data.low,
  //   variation: data.variation,
  //   amount: data.variation,
  //   quote_volume: data.quote_volume
  // }
  // store.dispatch(setPriceChart(chart));
}

function setMarketSummaryData(data: any) {
  // console.log(data);
  let summary = {
    first: data.first,
    last: data.last,
    high: data.high,
    low: data.low,
    variation: data.variation,
    variationAmount: data.variationAmount,
    amount: data.amount,
    quote_volume: data.quote_volume,
    pair: ''
  }
  console.log(data);
  store.dispatch(setSummary(summary));
}

function setAllMarketSummaryData(data: any) {
  // console.log(data);
  let allSumary: any = {};
  Object.keys(data).forEach((key) => {
    if (data[key].list)
      allSumary[key] = data[key].list;
  })

  console.log(data);
  store.dispatch(setAllSummary(allSumary));
}


export const unSubscribePair = (pair: string) => {
  let pairUnsubscribe = {
    method: "pairUnsubscribe",
    pair: pair,
  };

  let summary = {
    first: '0',
    last: '0.00',
    high: '0',
    low: '0',
    variation: '0%',
    variationAmount: '0',
    amount: '0',
    quote_volume: '0',
    pair: ''
  }
  store.dispatch(setSummary(summary));

  if (socketGlobal && socketGlobal.readyState == 1) {
    socketGlobal.send(JSON.stringify(pairUnsubscribe));
  }
};

export const subscribePair = (pair: string) => {
  let token: string = store.getState().User.token;
  let fingerprint: string = store.getState().User.fingerprint;
  // let pair: string = store.getState().User.pair;

  let messageObj: IMessageInitWs = {
    method: "pairSubscribe",
    pair: pair,
    origem: "APP",
    token: token,
    fingerprint,
  };

  if (socketGlobal && socketGlobal.readyState == 1) {
    socketGlobal.send(JSON.stringify(messageObj));
  }

};

