import React from "react";
import {
  formatNumberLocale,
  formatNumberLocaleTrim,
  getPriceDecimalsPlaces,
} from "../../utils/stringUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { IUserOrderData } from "../../state/features/UserOrder/userOrder.slice";
import { useUser } from "../../hooks/useUser";
import {
  cancelAllOrder,
  cancelOrder,
  cancelOrderStop,
} from "../../services/advanced";

interface IOrderHistory {
  orders: IUserOrderData[];
  closedData?: boolean;
  cancelAll: any;
  cancelItem: any;
  style?: string;
}

interface OrderData {
  crypto: string;
  asset: string;
  date: string;
  type: string;
  side: string;
  price: string;
  amount: string;
  pending: string;
  executed: string;
  value: string;
  stop?: string;
  status: string;
}

const OrderHistory = ({
  orders,
  closedData,
  cancelAll,
  cancelItem,
  style,
}: IOrderHistory) => {
  const { t } = useTranslation();

  // const { pair } = useUser();
  // const cancelAll = () => {
  //   console.log("CANCEL ALL");
  //   cancelAllOrder(pair).then(() => {

  //   }).catch(() => {

  //   })
  // };

  // const cancelItem = (orderID: string, status: string) => {
  //   console.log("CANCEL ITEM", orderID, status);
  //   if (status == 'OPEN')
  //     cancelOrder(orderID).then(() => {

  //     }).catch(() => {

  //     })
  //   else if (status == 'STOPPED')
  //     cancelOrderStop(orderID).then(() => {

  //     }).catch(() => {

  //     })
  // };

  const formatTime = (date: string | Date) => {
    let d = new Date(date);
    let day =
      d.getDate().toString().padStart(2, "0") +
      "/" +
      (d.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      d.getFullYear();
    let hours =
      d.getHours().toString().padStart(2, "0") +
      ":" +
      d.getMinutes().toString().padStart(2, "0") +
      ":" +
      d.getSeconds().toString().padStart(2, "0");
    return `${day} ${hours}`;
  };

  return (
    <div className={`${styles.container} ${style}`}>
      <div className={styles.ctCol}>
        <h6 className={styles.labelLeft}>{t("date")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemLeft}>{formatTime(entry.dateUpdated)}</p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("pair")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemRight}>{entry.pair.replace("_", "/")}</p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("type")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return <p className={styles.itemRight}>{entry.type}</p>;
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("side")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p
                className={
                  entry.side === "BUY" ? styles.buySide : styles.sellSide
                }>
                {t(entry.side)}
              </p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("price")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemRight}>
                {formatNumberLocale(entry.price, getPriceDecimalsPlaces(entry.pair))}
              </p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("amount")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemRight}>
                {formatNumberLocaleTrim(parseFloat(entry.amount), 8)}
              </p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>
          {!closedData ? t("pending") : t("average")}
        </h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemRight}>
                {!closedData
                  ? formatNumberLocaleTrim(parseFloat(entry.amountPending), 8)
                  : /* formatNumberLocaleTrim(parseFloat(entry.amountAverage), 8) */ formatNumberLocaleTrim(parseFloat(entry.executedPrice), getPriceDecimalsPlaces(entry.pair))}
              </p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("executed")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemRight}>
                {formatNumberLocaleTrim(
                  parseFloat(entry.amountExecuted || "0"),
                  8
                )}
              </p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("value")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return (
              <p className={styles.itemRight}>
                {formatNumberLocaleTrim(!closedData
                  ? entry.value : entry.executedValue, getPriceDecimalsPlaces(entry.pair))}
              </p>
            );
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={styles.labelRight}>{t("stop")}</h6>

        <div className={styles.ctList}>
          {orders.map((entry) => {
            return <p className={styles.itemRight}>{entry.stopPrice ? formatNumberLocaleTrim(entry.stopPrice, getPriceDecimalsPlaces(entry.pair)) : "-"}</p>;
          })}
        </div>
      </div>

      <div className={styles.ctCol}>
        <h6 className={!closedData ? styles.labelRight : styles.lastLabelGrey}>
          {t("status")}
        </h6>

        <div className={!closedData ? styles.ctList : styles.ctLastItem}>
          {orders.map((entry) => {
            return (
              <p className={!closedData ? styles.itemRight : styles.lastItem}>
                {entry.status}
              </p>
            );
          })}
        </div>
      </div>

      {!closedData ? (
        <div className={styles.ctCol}>
          <h6 className={styles.lastLabel} onClick={cancelAll}>
            {t("cancel all")}
          </h6>

          <div className={styles.ctLastItem}>
            {orders.map((entry) => {
              return (
                <div className={styles.lastItem}>
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={faTrashCan}
                    size="xl"
                    onClick={() => cancelItem(entry.orderID, entry.status)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OrderHistory;
