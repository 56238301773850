import React from "react";
import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { usePair } from "../../hooks/usePair";
import { useUser } from "../../hooks/useUser";
import {
  getPriceDigits,
  subscribePair,
  unSubscribePair,
} from "../../services/wsEngine";
import {
  unSubscribePair as unSubscribePairChart,
  subscribePair as subscribePairChart,
} from "../../services/wsChart";
import {
  hideLoading,
  showLoading,
} from "../../state/features/Loading/loading.slice";
import { useDispatch } from "react-redux";
import { formatNumberLocale } from "../../utils/stringUtils";
import { useSummary } from "../../hooks/useSummary";
import { useTrade } from "../../hooks/useTrade";
import { useAllSummary } from "../../hooks/useAllSummary";

interface IPairSearchBox {
  style?: string;
}

const PairSearchBox = ({ style }: IPairSearchBox) => {
  const { t } = useTranslation();

  const [input, setInput] = React.useState("");
  const [selectedFilterButton, setSelectedFilterButton] = React.useState(0);

  const selectFilterButton = (filter: number) => {
    setSelectedFilterButton(selectedFilterButton === filter ? 0 : filter);
  };

  const { pair, onSetPair } = useUser();
  const { cryptoSymbol, onSetCryptoSymbol } = usePair();
  const { assetSymbol, onSetAssetSymbol } = usePair();
  const { allSummary } = useAllSummary();
  const dispatch = useDispatch();

  const pairs = [
    {
      cryptoSymbol: "BTC",
      cryptoName: "Bitcoin",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      cryptoSymbol: "B2U",
      cryptoName: "B2U Coin",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      cryptoSymbol: "ETH",
      cryptoName: "Ethereum",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      cryptoSymbol: "CO2R",
      cryptoName: "CO2 Redress",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      imagem: "litecoin.png",
      cryptoName: "Litecoin",
      cryptoSymbol: "LTC",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      cryptoSymbol: "BABYDOGE",
      cryptoName: "Baby Doge",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      imagem: "ZFAI.png",
      cryptoName: "Zafira",
      cryptoSymbol: "ZFAI",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      imagem: "ZAIF.png",
      cryptoName: "Zaigar Finance",
      cryptoSymbol: "ZAIF",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      imagem: "radiocaca.png",
      cryptoName: "Radio Caca",
      cryptoSymbol: "RACA",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      imagem: "atlas.png",
      cryptoName: "Star Atlas",
      cryptoSymbol: "ATLAS",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      imagem: "mafa.png",
      cryptoName: "Mafagafo",
      cryptoSymbol: "MAFA",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      imagem: "abfy.png",
      cryptoName: "Ambify token",
      cryptoSymbol: "ABFY",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      imagem: "vpt.png",
      cryptoName: "Vacation Planet",
      cryptoSymbol: "VPT",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
    {
      imagem: "vra.png",
      cryptoName: "Verasity",
      cryptoSymbol: "VRA",
      assetSymbol: "BRLC",
      assetName: "Real",
    },
  ];

  const [filteredAsset, setFilteredAsset] = React.useState(pairs);
  const [filteredPairs, setFilteredPairs] = React.useState(pairs);

  const filterAsset = (pairFilter: string, buttonFilter: number) => {
    setFilteredAsset(
      selectedFilterButton === buttonFilter
        ? pairs
        : pairs.filter((entry) => {
            return entry.assetSymbol === pairFilter;
          })
    );
  };

  const subscribeNewPair = (asset: string, money: string) => {
    dispatch(showLoading());
    unSubscribePair(pair);
    unSubscribePairChart(pair);
    setPair(asset, money);
    onSetPair(`${asset}_${money}`);
    subscribePair(`${asset}_${money}`);
    subscribePairChart(`${asset}_${money}`);
    dispatch(hideLoading());
  };

  React.useEffect(() => {
    setFilteredPairs(
      filteredAsset.filter((entry) => {
        return (
          entry.cryptoSymbol.toLowerCase().includes(input.toLowerCase()) ||
          entry.cryptoName.toLowerCase().includes(input.toLowerCase()) ||
          entry.assetSymbol.toLowerCase().includes(input.toLowerCase()) ||
          entry.assetName.toLowerCase().includes(input.toLowerCase())
        );
      })
    );
  }, [filteredAsset, input]);

  const setPair = (cryptoSymbol: string, assetSymbol: string) => {
    onSetCryptoSymbol(cryptoSymbol);
    onSetAssetSymbol(assetSymbol);
  };

  return (
    <div className={`${styles.container} ${style}`}>
      <div className={styles.searchBar}>
        <FontAwesomeIcon className={styles.icon} icon={faMagnifyingGlass} />

        <input
          className={styles.searchInput}
          placeholder={t("search") || "Pesquisar"}
          value={input}
          onChange={(event) => {
            setInput(event.target.value);
          }}
        />
      </div>

      <div className={styles.ctFilterButtons}>
        <button
          className={
            selectedFilterButton === 1
              ? styles.selectedFilterButton
              : styles.filterButton
          }
          onClick={() => {
            selectFilterButton(1);
            filterAsset("BRLC", 1);
          }}
        >
          BRLC
        </button>
        {/* <button
          className={
            selectedFilterButton === 2
              ? styles.selectedFilterButton
              : styles.filterButton
          }
          onClick={() => {
            selectFilterButton(2);
            filterAsset("USDT", 2);
          }}>
          USDT
        </button> */}
      </div>

      <div className={styles.ctLabels}>
        <h3 className={styles.labelLeft}>{t("pair")}</h3>

        <h3 className={styles.labelRight}>{t("price")}</h3>

        <h3 className={styles.labelRight}>{t("var")}</h3>
      </div>

      <div className={styles.ctPairList}>
        {filteredPairs.map((entry) => (
          <div className={styles.ctPairItem}>
            <div
              className={
                cryptoSymbol === entry.cryptoSymbol &&
                assetSymbol === entry.assetSymbol
                  ? styles.selectedPairItemLeft
                  : styles.pairItemLeft
              }
              onClick={() =>
                subscribeNewPair(entry.cryptoSymbol, entry.assetSymbol)
              }
            >
              <p>{entry.cryptoSymbol}</p>
              <p className={styles.pairAsset}>{"/" + entry.assetSymbol}</p>
            </div>

            <div className={styles.pairItemRight}>
              <p
                className={
                  allSummary[`${entry.cryptoSymbol + "_" + entry.assetSymbol}`]
                    ?.side == "SELL"
                    ? styles.valueSell
                    : styles.valueBuy
                }
              >
                {formatNumberLocale(
                  allSummary[`${entry.cryptoSymbol + "_" + entry.assetSymbol}`]
                    ?.last,
                  getPriceDigits(
                    `${entry.cryptoSymbol + "_" + entry.assetSymbol}`
                  )
                )}
              </p>
            </div>

            <div className={styles.pairItemRight}>
              <p>
                <p
                  className={`${styles.info} ${
                    Math.sign(
                      parseFloat(
                        allSummary[
                          `${entry.cryptoSymbol + "_" + entry.assetSymbol}`
                        ]?.variation.replace("%", "")
                      )
                    ) === 1 || 0
                      ? styles.varPositive
                      : styles.varNegative
                  }`}
                >
                  {(Math.sign(
                    parseFloat(
                      allSummary[
                        `${entry.cryptoSymbol + "_" + entry.assetSymbol}`
                      ]?.variation.replace("%", "")
                    )
                  ) === 1 || 0
                    ? "+"
                    : "") +
                    (allSummary[
                      `${entry.cryptoSymbol + "_" + entry.assetSymbol}`
                    ]?.variation || "0%")}
                </p>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PairSearchBox;
