import React from "react";
import {
  formatNumberLocale,
  formatNumberLocaleTrim,
} from "../../../utils/stringUtils";
import InputBar from "../../InputBar";
import OrderValuePreset from "../../OrderValuePreset";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { usePair } from "../../../hooks/usePair";
import { useBuy } from "../../../hooks/useBuy";
import { useSell } from "../../../hooks/useSell";
import { toast } from "react-toastify";
import { IMarketBody, marketOrder } from "../../../services/advanced";
import { useBook } from "../../../hooks/useBook";
import { IBookData } from "../../../state/features/Book/book.slice";

interface IMarket {
  buyOrSell?: string;
  style?: string;
}

const Market = ({ buyOrSell, style }: IMarket) => {
  const { t } = useTranslation();

  const formatTo = (value: string, limit: number) => {
    value = value.replaceAll(",", ".");
    let i = value.lastIndexOf(".");

    if (i > 0) {
      let integer = value.substring(0, i).replaceAll(".", "");
      let decimals = value.substring(i + 1, value.length);
      decimals = decimals.substring(0, limit);
      value = integer + "." + decimals;
    }

    return value;
  };

  const { cryptoSymbol } = usePair();
  const { cryptoBalance } = usePair();
  const { assetSymbol } = usePair();
  const { assetBalance } = usePair();

  const [nZero, setNZero] = React.useState(
    cryptoSymbol === "BABYDOGE" ? 12 : 8
  );

  React.useEffect(() => {
    setNZero(cryptoSymbol === "BABYDOGE" ? 12 : 8);
  }, [cryptoSymbol]);

  const { askBook, bidBook } = useBook();

  const { buyMarketPrice, onSetPriceMarketBuy } = useBuy();
  const { buyMarketAmount, onSetAmountMarketBuy } = useBuy();
  const { buyMarketTotal, onSetTotalMarketBuy } = useBuy();

  const { sellMarketPrice, onSetPriceMarketSell } = useSell();
  const { sellMarketAmount, onSetAmountMarketSell } = useSell();
  const { sellMarketTotal, onSetTotalMarketSell } = useSell();

  const marketPrice = (amount: string, buyOrSell: string) => {
    let book: IBookData[] = buyOrSell == "buy" ? [...askBook] : bidBook;
    if (buyOrSell == "buy") {
      book.reverse();
    }
    let restAmount = Number(amount);
    let price: number;
    let qtd = 0;
    let firstLine = 0;
    book.forEach((row: IBookData) => {
      if (buyOrSell == "buy") {
        console.log(restAmount, Number(row.amount) * Number(row.price));
        if (restAmount >= Number(row.amount) * Number(row.price)) {
          firstLine += Number(row.amount) * Number(row.price);
          restAmount -= Number(row.amount) * Number(row.price);
          qtd += Number(row.amount);
        } else if (
          restAmount > 0 &&
          restAmount < Number(row.amount) * Number(row.price)
        ) {
          firstLine += Number(restAmount) * Number(row.price);
          qtd += restAmount;
          restAmount = 0;
        }
      } else {
        if (restAmount >= Number(row.amount)) {
          firstLine += Number(row.amount) * Number(row.price);
          restAmount -= Number(row.amount);
          qtd += Number(row.amount);
        } else if (restAmount > 0 && restAmount < Number(row.amount)) {
          firstLine += Number(restAmount) * Number(row.price);
          qtd += restAmount;
          restAmount = 0;
        }
      }
    });

    price = firstLine / qtd;
    if (isNaN(Number(price))) return 0;
    else return price.toFixed(2);
  };

  React.useEffect(() => {
    let number = (
      parseFloat(formatTo(buyMarketTotal, nZero)) /
      parseFloat(formatTo(buyMarketPrice, nZero))
    )
      .toFixed(8)
      .toString();
    onSetAmountMarketBuy(number !== "NaN" ? number : "0");
  }, [
    buyMarketPrice,
    buyMarketTotal,
    nZero,
    onSetAmountMarketBuy,
    onSetPriceMarketBuy,
  ]);

  React.useEffect(() => {
    onSetTotalMarketSell(
      (
        parseFloat(formatTo(sellMarketPrice, nZero)) *
        parseFloat(formatTo(sellMarketAmount, nZero))
      )
        .toFixed(2)
        .toString()
    );
  }, [onSetTotalMarketSell, sellMarketPrice, sellMarketAmount, nZero]);

  React.useMemo(() => {
    onSetPriceMarketBuy(
      marketPrice(buyMarketTotal.replace(",", ".").toString(), "buy") || "0"
    );
  }, [buyMarketTotal, onSetPriceMarketBuy]);

  React.useMemo(() => {
    onSetPriceMarketSell(
      marketPrice(sellMarketAmount.replace(",", ".").toString(), "sell") || "0"
    );
  }, [onSetPriceMarketSell, sellMarketAmount]);

  const buyMsg = () => (
    <div className={styles.ctMsg}>
      <p className={styles.msgTitle}>{t("new buy market order")}</p>
      <p className={styles.msgBody}>
        {t("sent buy market order")} {buyMarketAmount} {cryptoSymbol}{" "}
        {t("using")} {assetSymbol}
      </p>
    </div>
  );

  const sellMsg = () => (
    <div className={styles.ctMsg}>
      <p className={styles.msgTitle}>{t("new sell market order")}</p>
      <p className={styles.msgBody}>
        {t("sent sell market order")} {sellMarketAmount} {cryptoSymbol}{" "}
        {t("using")} {assetSymbol}
      </p>
    </div>
  );

  const notify = (msg: any) => {
    toast.success(msg, {
      style: { backgroundColor: "rgb(43, 49, 57)" },
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const successHandler = (res: any, type: string) => {
    let data = res.data.data;
    if (data.message) data = data.message;
    console.log(data);
    if (data === "Insuficient balance") {
      notify(t("insufficient balance"));
    } else if (data === "Amount invalid") {
      if (
        cryptoSymbol === "B2U" &&
        Number(sellMarketAmount) > 100.0 &&
        buyOrSell === "sell"
      ) {
        notify(t("b2u 100k limite"));
      } else notify(t("invalid value"));
    } else if (data === "Invalid price") {
      notify(t("invalid price 30"));
    } else if (
      data === "Transação não realizada, tente novamente em alguns segundos"
    ) {
      notify(t("transaction not made"));
    } else if (data === "Total value must be superior to 10BRL") {
      notify(t("superior 10BRL"));
    } else {
      notify(type === "buy" ? buyMsg() : sellMsg());
    }
  };

  const errorHandler = (err: any) => {
    let data = err.response.data
      ? err.response.data.data
        ? err.response.data.data.message
          ? err.response.data.data.message
          : err.response.data.data
        : ""
      : "";
    console.log(data);
    if (data === "Insuficient balance") {
      notify(t("insufficient balance"));
    } else if (data && data.split('#')[0] === "Invalid b2u price") {
      notify(t("b2u 100k limite").toString() + ' ' + data.split('#')[1]);
    } else if (data && data.split('#')[0] === "Invalid b2u amount") {
      notify(t("b2u 100k limite").toString() + ' ' + data.split('#')[1]);
    } else if (data === "Invalid amount") {
      notify(t("invalid value"));
    } else if (data === "Amount invalid") {
      notify(t("invalid value"));
    } else if (data === "Invalid price") {
      notify(t("invalid price"));
    } else if (data === "Invalid above 30 variation") {
      notify(t("invalid price 30"));
    } else if (
      data === "Transação não realizada, tente novamente em alguns segundos"
    ) {
      notify(t("transaction not made"));
    } else if (data === "Total value must be superior to 10BRL") {
      notify(t("superior 10BRL"));
    }
  };

  const confirmBuy = () => {
    let body: IMarketBody = {
      pair: cryptoSymbol + "_" + assetSymbol,
      amount: buyMarketTotal,
      side: "BUY",
    };
    marketOrder(body)
      .then((res) => {
        successHandler(res, "buy");
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const confirmSell = () => {
    let body: IMarketBody = {
      pair: cryptoSymbol + "_" + assetSymbol,
      amount: sellMarketAmount,
      side: "SELL",
    };
    marketOrder(body)
      .then((res) => {
        successHandler(res, "sell");
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  return (
    <div className={`${styles.container} ${style}`}>
      <div
        className={
          buyOrSell === "sell" ? styles.ctInputsHide : styles.ctInputsShow
        }>
        <div className={styles.ctBalance}>
          <p className={styles.balanceText}>{t("available")}</p>
          <p className={styles.balanceValue}>
            {formatNumberLocale(assetBalance, 2) + " " + assetSymbol}
          </p>
        </div>

        <InputBar
          prefix={t("price")}
          suffix={assetSymbol}
          input={buyMarketPrice}
          setInput={onSetPriceMarketBuy}
          disabled={true}
          style={styles.inputField}
        />

        <InputBar
          prefix={t("total")}
          suffix={assetSymbol}
          input={buyMarketTotal}
          setInput={onSetTotalMarketBuy}
          style={styles.inputField}
        />

        <OrderValuePreset
          price={"1"}
          setAmount={onSetTotalMarketBuy}
          wallet={assetBalance}
          buyOrSell="buy"
          style={styles.presetField}
        />

        <InputBar
          prefix={t("amount")}
          suffix={cryptoSymbol}
          input={buyMarketAmount}
          disabled={true}
          style={styles.inputField}
        />

        <button className={styles.confirmBuy} onClick={() => confirmBuy()}>
          {t("buy")} {cryptoSymbol}
        </button>
      </div>

      <div
        className={
          buyOrSell === "buy" ? styles.ctInputsHide : styles.ctInputsShow
        }>
        <div className={styles.ctBalance}>
          <p className={styles.balanceText}>{t("available")}</p>
          <p className={styles.balanceValue}>
            {formatNumberLocaleTrim(cryptoBalance, 8) + " " + cryptoSymbol}
          </p>
        </div>

        <InputBar
          prefix={t("price")}
          suffix={assetSymbol}
          input={sellMarketPrice}
          setInput={onSetPriceMarketSell}
          disabled={true}
          style={styles.inputField}
        />

        <InputBar
          prefix={t("amount")}
          suffix={cryptoSymbol}
          input={sellMarketAmount}
          setInput={onSetAmountMarketSell}
          style={styles.inputField}
        />

        <OrderValuePreset
          price={sellMarketPrice}
          setAmount={onSetAmountMarketSell}
          wallet={cryptoBalance}
          buyOrSell="sell"
          style={styles.presetField}
        />

        <InputBar
          prefix={t("total")}
          suffix={assetSymbol}
          input={sellMarketTotal}
          disabled={true}
          style={styles.inputField}
        />

        <button className={styles.confirmSell} onClick={() => confirmSell()}>
          {t("sell")} {cryptoSymbol}
        </button>
      </div>
    </div>
  );
};

export default Market;
