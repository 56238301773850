import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE: any = {};

export const allSummarySlice = createSlice({
  name: "allSumary",
  initialState: INITIAL_STATE,
  reducers: {
    setAllSummary: (state, action: PayloadAction<any>) => {
      state = action.payload;
      return state;
    },

    signOut: (state) => {
      state = INITIAL_STATE;
      return state;
    },
  },
});

export const { setAllSummary } = allSummarySlice.actions;
export const allSummaryReducer = allSummarySlice.reducer;
