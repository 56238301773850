import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISummarySlice {
  first: string;
  last: string;
  high: string;
  low: string;
  variation: string;
  variationAmount: string;
  amount: string;
  quote_volume: string;
  pair: string;
}

const INITIAL_STATE: ISummarySlice = {
  first: "",
  last: "",
  high: "",
  low: "",
  variation: "",
  amount: "",
  quote_volume: "",
  variationAmount: "",
  pair: ""
};

export const summarySlice = createSlice({
  name: "trade",
  initialState: INITIAL_STATE,
  reducers: {
    setSummary: (state, action: PayloadAction<ISummarySlice>) => {
      state = action.payload;
      return state;
    },

    signOut: (state) => {
      state = INITIAL_STATE;
      return state;
    },
  },
});

export const { setSummary } = summarySlice.actions;
export const summaryReducer = summarySlice.reducer;
