const ApiConfig = {
  isDevApi: true,
  BASE_URL: 'https://api.publicapis.org',
  LOGIN: '/api/users/login',
  USERS: '/api/users',
  ENTRIES: '/entries',
  RANDOM: '/random',
  PORTAL_URL: 'https://portal.bitcointoyou.com',
  PUBLIC_WEBSOCKET_BS: 'wss://xerox.bitcointoyou.com:443/binanceService/v1',
  API_BS: 'https://xerox.bitcointoyou.com:443/binanceService/v1',
  CLIENT_CS: 'https://back.bitcointoyou.com:443/api/v1',
  CLIENT_ENGINE: 'https://back.bitcointoyou.com:443/api/v2',
  PUBLIC_DEVICE_WEBSOCKET: 'wss://back.bitcointoyou.com:443/api/v1',
  PUBLIC_WEBSOCKET: 'wss://back.bitcointoyou.com:443',
  PRIVATE_WEBSOCKET: 'wss://back.bitcointoyou.com:443',
  DEVICE_WEBSOCKET: 'wss://back.bitcointoyou.com:443/api/v1',
  API_CHART_URL: 'https://data.bitcointoyou.com:443/api',
  PUBLIC_CHART_WEBSOCKET: 'wss://data.bitcointoyou.com:443'
};

// const ApiConfig = {
//     isDevApi: true,
//     BASE_URL: 'https://api.publicapis.org',
//     LOGIN: '/api/users/login',
//     USERS: '/api/users',
//     ENTRIES: '/entries',
//     RANDOM: '/random',
//     PORTAL_URL: 'https://xeroxportal.bitcointoyou.com',
//     PUBLIC_WEBSOCKET_BS: 'wss://xeroxbinance.bitcointoyou.com:443/binanceService/v1',
//     API_BS: 'https://xeroxbinance.bitcointoyou.com:443/binanceService/v1',
//     PUBLIC_DEVICE_WEBSOCKET: 'wss://xeroxback.bitcointoyou.com:443/api/v1',
//     CLIENT_CS: 'https://xeroxback.bitcointoyou.com:443/api/v1',
//     CLIENT_ENGINE: 'https://xeroxback.bitcointoyou.com:443/api/v2',
//     PUBLIC_WEBSOCKET: 'wss://xeroxback.bitcointoyou.com:443',
//     PRIVATE_WEBSOCKET: 'wss://xeroxback.bitcointoyou.com:443',
//     DEVICE_WEBSOCKET: 'wss://xeroxback.bitcointoyou.com:443/api/v1',
//     API_CHART_URL: 'https://xeroxchart.bitcointoyou.com:443/api',
//     PUBLIC_CHART_WEBSOCKET: 'wss://xeroxchart.bitcointoyou.com:443',
//     tokenTeste: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOiIzMDk4IiwicGVyZmlsIjoiQURNSU4iLCJmaW5nZXJwcmludCI6IjVlNmY5YjM4OWM3MGUzYjY4MTA4MGZhNDYwMDJmYWRiIiwiaXNMaW1pdGVkQWNjZXNzIjpmYWxzZSwiaWF0IjoxNjYwMTYxNzI1LCJleHAiOjE2NjI3NTM3MjV9.taGD_-EMsmAshp0lux9q_7zSTYyXgv4-HeZmg9vtGy0'
// };

// const ApiConfig = {
//     isDevApi: true,
//     BASE_URL: 'https://api.publicapis.org',
//     LOGIN: '/api/users/login',
//     USERS: '/api/users',
//     ENTRIES: '/entries',
//     RANDOM: '/random',
//     PORTAL_URL: 'http://localhost:4200',
//     PUBLIC_WEBSOCKET_BS: 'ws://localhost:3103/binanceService/v1',
//     API_BS: 'http://localhost:3103/binanceService/v1',
//     PUBLIC_DEVICE_WEBSOCKET: 'ws://localhost:3100/api/v1',
//     CLIENT_CS: 'http://localhost:3100/api/v1',
//     CLIENT_ENGINE: 'http://localhost:3102/api/v2',
//     PUBLIC_WEBSOCKET: 'ws://localhost:3102',
//     PRIVATE_WEBSOCKET: 'ws://localhost:3102',
//     DEVICE_WEBSOCKET: 'ws://localhost:3100/api/v1',
//     API_CHART_URL: 'http://localhost:3101.bitcointoyou.com:443/api',
//     PUBLIC_CHART_WEBSOCKET: 'ws://localhost:3101',
//     tokenTeste: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOiIzMDk4IiwicGVyZmlsIjoiQURNSU4iLCJmaW5nZXJwcmludCI6IjVlNmY5YjM4OWM3MGUzYjY4MTA4MGZhNDYwMDJmYWRiIiwiaXNMaW1pdGVkQWNjZXNzIjpmYWxzZSwiaWF0IjoxNjYwMTYxNzI1LCJleHAiOjE2NjI3NTM3MjV9.taGD_-EMsmAshp0lux9q_7zSTYyXgv4-HeZmg9vtGy0'
// };

export default ApiConfig;