import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IInfoSlice {
  isLoggedIn: boolean;
  buyOrSell: string;
}

const INITIAL_STATE: IInfoSlice = {
  isLoggedIn: false,
  buyOrSell: "buy",
};

export const infoSlice = createSlice({
  name: "info",
  initialState: INITIAL_STATE,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
      return state;
    },

    setBuyOrSell: (state, action: PayloadAction<string>) => {
      state.buyOrSell = action.payload;
      return state;
    },
  },
});

export const { setIsLoggedIn, setBuyOrSell } = infoSlice.actions;
export const infoReducer = infoSlice.reducer;
