import * as React from 'react';
import './index.css';
import {
	widget,
	ChartingLibraryWidgetOptions,
	LanguageCode,
	IChartingLibraryWidget,
	ResolutionString,
} from '../../charting_library';
import { useUser } from '../../hooks/useUser';
import { dataFeed } from '../../services/tv';

export interface ChartContainerProps {
	symbol: ChartingLibraryWidgetOptions['symbol'];
	interval: ChartingLibraryWidgetOptions['interval'];

	// BEWARE: no trailing slash is expected in feed URL
	datafeedUrl: string;
	libraryPath: ChartingLibraryWidgetOptions['library_path'];
	chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
	chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
	clientId: ChartingLibraryWidgetOptions['client_id'];
	userId: ChartingLibraryWidgetOptions['user_id'];
	fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
	autosize: ChartingLibraryWidgetOptions['autosize'];
	studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
	container: ChartingLibraryWidgetOptions['container'];
}
const lightOveride = {
	"mainSeriesProperties.showCountdown": true,
	"paneProperties.background": "#FFFFFF",
	"": "",
	"paneProperties.vertGridProperties.color": "#999",
	"paneProperties.horzGridProperties.color": "#999",
	"symbolWatermarkProperties.transparency": 90,
	"scalesProperties.textColor": "#555",
	"mainSeriesProperties.candleStyle.wickUpColor": '#336854',
	"mainSeriesProperties.candleStyle.wickDownColor": '#7f323f',
}
const darkOveride = {
	"chartProperties.backgroundColor": "#0F171F",
	"mainSeriesProperties.showCountdown": true,
	"paneProperties.background": "#0F171F",
	"paneProperties.vertGridProperties.color": "#363c4e",
	"paneProperties.horzGridProperties.color": "#363c4e",
	"symbolWatermarkProperties.transparency": 90,
	"scalesProperties.textColor": "#AAA",
	"mainSeriesProperties.candleStyle.wickUpColor": '#336854',
	"mainSeriesProperties.candleStyle.wickDownColor": '#7f323f',
	"drawingToolbar.backgroundColor": "#0F171F"
}

export interface ChartContainerState {
}

// function getLanguageFromURL(): LanguageCode | null {
// 	const regex = new RegExp('[\\?&]lang=([^&#]*)');
// 	const results = regex.exec(location.search);
// 	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
// }

export class TVChartContainer extends React.PureComponent<Partial<ChartContainerProps>, ChartContainerState> {

	public getUserData = () => {
		return useUser();
	}

	public static defaultProps: Omit<ChartContainerProps, 'container'> = {
		symbol: 'B2U_BRLC',
		interval: '1440' as ResolutionString,
		datafeedUrl: 'https://demo_feed.tradingview.com',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};

	private tvWidget: IChartingLibraryWidget | null = null;
	private ref: React.RefObject<HTMLDivElement> = React.createRef();

	public componentDidMount(): void {
		if (!this.ref.current) {
			return;
		}

		const widgetOptions: ChartingLibraryWidgetOptions = {
			symbol: this.props.symbol as string,
			// BEWARE: no trailing slash is expected in feed URL
			// tslint:disable-next-line:no-any
			datafeed: dataFeed,//new (window as any).Datafeeds.UDFCompatibleDatafeed(this.props.datafeedUrl),
			interval: this.props.interval as ChartingLibraryWidgetOptions['interval'],
			container: this.ref.current,
			library_path: this.props.libraryPath as string,

			locale: 'en',//getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: this.props.fullscreen,
			autosize: this.props.autosize,
			studies_overrides: this.props.studiesOverrides,
			overrides: darkOveride,
			theme: 'Dark'
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		setTimeout(() => {
			let element = document.getElementsByTagName('iframe').item(0);
			if (element) {
				let iframe: any = element.contentDocument;
				let opcMenu = iframe.getElementsByClassName('study').item(0).getElementsByClassName('apply-overflow-tooltip');
				// console.log('vamos ver aqui iframe ==> ', iframe.item(0).innerHTML);
				opcMenu.item(0).click();

				let menu = iframe.getElementsByClassName('topmenu').item(0);
				let opcMenuItems = menu.querySelector('table tbody').getElementsByTagName('tr');
				let menuOPCFinal = null
				for (let i = 0; i < opcMenuItems.length; i++) {
					let value = opcMenuItems.item(i).getElementsByClassName('text');
					if (value.item(0).innerHTML === 'Desmesclar para baixo' || value.item(0).innerHTML === 'Unmerge Down') {
						menuOPCFinal = opcMenuItems.item(i)
					}
				}

				menuOPCFinal.click();
			}


		}, 1000)

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				const button = tvWidget.createButton();
				button.setAttribute('title', 'Click to show a notification popup');
				button.classList.add('apply-common-tooltip');
				button.addEventListener('click', () => tvWidget.showNoticeDialog({
					title: 'Notification',
					body: 'TradingView Charting Library API works correctly',
					callback: () => {
						console.log('Noticed!');
					},
				}));
				button.innerHTML = 'Check API';
			});
		});

	}

	componentDidUpdate(prevProps: ChartContainerProps) {
		if (prevProps.symbol !== this.props.symbol && this.tvWidget) {
			this.tvWidget.setSymbol(this.props.symbol || 'B2U_BRLC', this.props.interval as ResolutionString, () => { })
		}
	}

	public componentWillUnmount(): void {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	public render(): JSX.Element {
		return (
			<div
				ref={this.ref}
				className={'TVChartContainer'}
			/>
		);
	}
}
