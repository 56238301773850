import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ptBR from "./locale/pt-BR.json";
import enUS from "./locale/en-US.json";

let deviceLanguage: string = navigator.language;

deviceLanguage =
  deviceLanguage.replace("-", "_") === "pt_BR" ? "pt_BR" : "en_US";

i18n.use(initReactI18next).init({
  fallbackLng: deviceLanguage,
  resources: {
    pt_BR: ptBR,
    en_US: enUS,
  },
  lng: deviceLanguage,
  ns: "translation",
  defaultNS: "translation",
});

export default i18n;
