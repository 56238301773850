import React from "react";
import OrderHistory from "../OrderHistory";
import SwitchTabs from "../SwitchTabs";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from "react-device-detect";
import OrderHistoryMobile from "../OrderHistoryMobile";
import { toast } from "react-toastify";
import { useUserOrders } from "../../hooks/useUserOrders";
import {
  cancelAllOrder,
  cancelOrder,
  cancelOrderStop,
} from "../../services/advanced";
import { useUser } from "../../hooks/useUser";
import TradeHistory from "../TradeHistory";
import TradeHistoryMobile from "../TradeHistoryMobile";

interface IHistoryTabs {
  style?: string;
}

const HistoryTabs = ({ style }: IHistoryTabs) => {
  const { t } = useTranslation();

  const { openOrders, executedOrders, tradeOrders } = useUserOrders();

  const openOrdersData = [
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Stop",
      side: "Compra",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      stop: "4309",
      status: "Aberta",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Limite",
      side: "Venda",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      status: "Aberta",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Stop",
      side: "Compra",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      stop: "4309",
      status: "Aberta",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Limite",
      side: "Venda",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      status: "Aberta",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Stop",
      side: "Venda",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      stop: "4309",
      status: "Aberta",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Stop",
      side: "Compra",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      stop: "4309",
      status: "Aberta",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Limite",
      side: "Compra",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      status: "Aberta",
    },
  ];

  const closedOrdersData = [
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Limite",
      side: "Compra",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      status: "Fechada",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Limite",
      side: "Venda",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      status: "Fechada",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Limite",
      side: "Compra",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      status: "Fechada",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Limite",
      side: "Venda",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      status: "Fechada",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      type: "Limite",
      side: "Venda",
      price: "160000",
      amount: "5",
      pending: "5",
      executed: "0%",
      value: "80000",
      status: "Fechada",
    },
  ];

  const tradeOrdersData = [
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      side: "Compra",
      price: "160000",
      executed: "160000",
      function: "Maker",
      total: "160000",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      side: "Venda",
      price: "160000",
      executed: "160000",
      function: "Taker",
      total: "160000",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      side: "Compra",
      price: "160000",
      executed: "160000",
      function: "Maker",
      total: "160000",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      side: "Venda",
      price: "160000",
      executed: "160000",
      function: "Taker",
      total: "160000",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      side: "Venda",
      price: "160000",
      executed: "160000",
      function: "Taker",
      total: "160000",
    },
    {
      crypto: "ETH",
      asset: "USDT",
      date: "01/01/2022 05:36:29",
      side: "Compra",
      price: "160000",
      executed: "160000",
      function: "Maker",
      total: "160000",
    },
  ];

  const tabs = [t("open orders"), t("order history"), t("trade history")];
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { pair } = useUser();

  const cancelAllMsg = () => (
    <div className={styles.ctMsg}>
      <p className={styles.msgTitle}>Ordens canceladas!</p>
      <p className={styles.msgBody}>
        Todas as ordens em aberto foram canceladas
      </p>
    </div>
  );

  const cancelItemMsg = () => (
    <div className={styles.ctMsg}>
      <p className={styles.msgTitle}>Ordem cancelada!</p>
      <p className={styles.msgBody}>A ordem foi cancelada</p>
    </div>
  );

  const notify = (msg: any) => {
    toast.warning(msg, {
      style: { backgroundColor: "rgb(43, 49, 57)" },
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const cancelAll = () => {
    cancelAllOrder(pair)
      .then(() => {
        notify(cancelAllMsg);
      })
      .catch(() => { });
  };

  const cancelItem = (orderID: string, status: string) => {
    if (status === "OPEN")
      cancelOrder(orderID)
        .then(() => {
          notify(cancelItemMsg);
        })
        .catch(() => { });
    else if (status === "STOPPED")
      cancelOrderStop(orderID)
        .then(() => {
          notify(cancelItemMsg);
        })
        .catch(() => { });
  };

  return (
    <div className={`${styles.container} ${style}`}>
      <SwitchTabs
        labels={tabs}
        selected={selectedTab}
        setSelected={setSelectedTab}
        style={styles.tabs}
      />

      <BrowserView>
        {selectedTab === 0 ? (
          <OrderHistory
            orders={openOrders}
            cancelAll={cancelAll}
            cancelItem={cancelItem}
          />
        ) : selectedTab === 1 ? (
          <OrderHistory
            orders={executedOrders}
            closedData={true}
            cancelAll={cancelAll}
            cancelItem={cancelItem}
          />
        ) : <TradeHistory orders={tradeOrders} />
        }
      </BrowserView>

      <MobileView>
        {selectedTab === 0 ? (
          openOrders[0] ? (
            <>
              <p className={styles.mobileCancelAll} onClick={() => cancelAll()}>
                {t("cancel all")}
              </p>
              <OrderHistoryMobile orders={openOrders} cancelItem={cancelItem} />
            </>
          ) : null
        ) : selectedTab === 1 ? (
          <OrderHistoryMobile orders={executedOrders} cancelItem={cancelItem} />
        ) : <TradeHistoryMobile orders={tradeOrders} />
        }
      </MobileView>
    </div>
  );
};

export default HistoryTabs;
