

// export class StreamProvider {
//     constructor(
//         private http: HttpClient,
//         private historyProvider: HistoryProvider,
//         public websocketService: WebsocketChartService,
//         private connectService: ConnectService
//     ) {
//         this.candleChartData = this.websocketService.getCandleChartData()

import { clientHistory } from "./clientHistory";
import { candleChartData$ } from "./wsChart";

//     }
var _subs = [];
var socket;
var data = [];
var candleChartData;
var symbol

function subscribeBars(symbolInfo: any, resolution: any, updateCb: any, uid: any, resetCache: any) {
    console.log(symbolInfo, resetCache)
    resetCache();
    candleChartData$.subscribe((list) => {
        const data = list.map((el: any) => {
            // console.log('vamos ver ==> ', el)
            return {
                time: new Date(el.date).getTime(), //TradingView requires bar time in ms
                low: el.low,
                high: el.high,
                open: el.open,
                close: el.close,
                volume: Number(el.volume)
            }
        }).sort((a: any, b: any) => {
            return a.time - b.time;
        });

        data.forEach((item: any) => {
            let i = findInData(item);
            if (i) {
                updateCb(item);
            }
        });
    });

    // this.socket.on('m', (e) => {
    //     // here we get all events the CryptoCompare connection has subscribed to
    //     // we need to send this new data to our subscribed charts
    //     const _data = e.split('~')
    //     if (_data[0] === "3") {
    //         // console.log('Websocket Snapshot load event complete')
    //         return
    //     }
    //     const data = {
    //         sub_type: parseInt(_data[0], 10),
    //         exchange: _data[1],
    //         to_sym: _data[2],
    //         from_sym: _data[3],
    //         trade_id: _data[5],
    //         ts: parseInt(_data[6], 10),
    //         volume: parseFloat(_data[7]),
    //         price: parseFloat(_data[8])
    //     }

    //     const channelString = `${data.sub_type}~${data.exchange}~${data.to_sym}~${data.from_sym}`

    //     const sub = this._subs.find(e => e.channelString === channelString)

    //     if (sub) {
    //         // disregard the initial catchup snapshot of trades for already closed candles
    //         if (data.ts < sub.lastBar.time / 1000) {
    //             return
    //         }

    //         var _lastBar = this.updateBar(data, sub)

    //         // send the most recent bar back to TV's realtimeUpdate callback
    //         sub.listener(_lastBar)
    //         // update our own record of lastBar
    //         sub.lastBar = _lastBar
    //     }
    // })
}

function unsubscribeBars(uid: any) {
    // var subIndex = this._subs.findIndex(e => e.uid === uid)
    // if (subIndex === -1) {
    //     //console.log("No subscription found for ",uid)
    //     return;
    // }
    // var sub = this._subs[subIndex];
    // this.socket.emit('SubRemove', { subs: [sub.channelString] });
    // this._subs.splice(subIndex, 1);
}


function findInData(value: any) {
    // console.log('history last', clientHistory.getHistoryVar())
    return !clientHistory.getHistoryVar() || !clientHistory.getHistoryVar().lastBar || value.time >= clientHistory.getHistoryVar().lastBar.time;
    // let verify = false;
    // this.data.forEach((item) => {
    //     if (value.time === item.time && value.volume === item.volume) {
    //         verify = true;
    //     }
    // })
    // return verify;
}

function updateBar(data: any, sub: any) {
    var lastBar = sub.lastBar
    let resolution = sub.resolution
    if (resolution.includes('D')) {
        // 1 day in minutes === 1440
        resolution = 1440
    } else if (resolution.includes('W')) {
        // 1 week in minutes === 10080
        resolution = 10080
    }
    var coeff = resolution * 60
    // console.log({coeff})
    var rounded = Math.floor(data.ts / coeff) * coeff
    var lastBarSec = lastBar.time / 1000
    var _lastBar

    if (rounded > lastBarSec) {
        // create a new candle, use last close as open **PERSONAL CHOICE**
        _lastBar = {
            time: rounded * 1000,
            open: lastBar.close,
            high: lastBar.close,
            low: lastBar.close,
            close: data.price,
            volume: data.volume
        }

    } else {
        // update lastBar candle!
        if (data.price < lastBar.low) {
            lastBar.low = data.price
        } else if (data.price > lastBar.high) {
            lastBar.high = data.price
        }

        lastBar.volume += data.volume
        lastBar.close = data.price
        _lastBar = lastBar
    }
    return _lastBar
}

// takes symbolInfo object as input and creates the subscription string to send to CryptoCompare
function createChannelString(symbolInfo: any) {
    var channel = symbolInfo.name.split(/[:/]/)
    const exchange = channel[0] === 'GDAX' ? 'Coinbase' : channel[0]
    const to = channel[2]
    const from = channel[1]
    // subscribe to the CryptoCompare trade channel for the pair and exchange
    return `0~${exchange}~${from}~${to}`
}
// }

export const stream = {
    createChannelString,
    updateBar,
    subscribeBars,
    unsubscribeBars
}
