import React from "react";
import SwitchTabs from "../SwitchTabs";
import Limit from "./Limit";
import Market from "./Market";
import Stop from "./Stop";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from "react-device-detect";
import { useInfo } from "../../hooks/useInfo";

interface IBuyAndSell {
  style?: string;
}

const BuyAndSell = ({ style }: IBuyAndSell) => {
  const { t } = useTranslation();

  const tabs = [t("limit"), t("market order"), t("stop")];
  const [selectedTab, setSelectedTab] = React.useState(0);

  const { buyOrSell } = useInfo();

  return (
    <div className={`${styles.container} ${style}`}>
      <SwitchTabs
        labels={tabs}
        selected={selectedTab}
        setSelected={setSelectedTab}
        style={styles.tabs}
      />

      <BrowserView>
        {selectedTab === 0 ? (
          <Limit />
        ) : selectedTab === 1 ? (
          <Market />
        ) : (
          <Stop />
        )}
      </BrowserView>

      <MobileView>
        {selectedTab === 0 ? (
          <Limit buyOrSell={buyOrSell} />
        ) : selectedTab === 1 ? (
          <Market buyOrSell={buyOrSell} />
        ) : (
          <Stop buyOrSell={buyOrSell} />
        )}
      </MobileView>
    </div>
  );
};

export default BuyAndSell;
