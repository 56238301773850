import React from "react";
import { formatNumberLocale } from "../../utils/stringUtils";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { usePair } from "../../hooks/usePair";
import PairSearchModal from "../PairSearchModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useSummary } from "../../hooks/useSummary";
import { useTrade } from "../../hooks/useTrade";
import { getPriceDigits } from "../../services/wsEngine";

interface IPairInfoBoxMobile {
  value: string;
  type: string;
  style?: string;
}

const PairInfoBoxMobile = ({ value, type, style }: IPairInfoBoxMobile) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = React.useState(false);

  const { cryptoSymbol } = usePair();
  const { assetSymbol } = usePair();
  const { summary } = useSummary();
  const { trades } = useTrade();
  value = trades[0] ? trades[0].price : '0';
  type = trades[0] ? trades[0].side : '0';

  const [var24, setVar24] = React.useState(["289.60", "1.78"]);
  const [max24, setMax24] = React.useState("16548.71");
  const [min24, setMin24] = React.useState("15996.27");
  const [vol24, setVol24] = React.useState("4212172416.41");

  return (
    <>
      <PairSearchModal show={showModal} setShow={setShowModal} />

      <div className={`${styles.container} ${style}`}>
        <div className={styles.ctCol}>
          <div className={styles.ctInfoPair} onClick={() => setShowModal(true)}>
            <div className={styles.ctRow}>
              <p className={styles.crypto}>{cryptoSymbol}</p>

              <FontAwesomeIcon className={styles.icon} icon={faCaretDown} />
            </div>

            <p className={styles.asset}>{"/" + assetSymbol}</p>
          </div>

          <div className={styles.ctInfo}>
            <p className={type === "BUY" ? styles.valueBuy : styles.valueSell}>
              {formatNumberLocale(value, getPriceDigits(`${cryptoSymbol + "_" + assetSymbol}`))}
            </p>
          </div>
        </div>

        <div className={styles.ctCol}>
          <div className={styles.ctInfo}>
            <h4 className={styles.label}>{t("var24")}</h4>

            <p
              className={`${styles.info} ${
                Math.sign(parseFloat(summary.variation.replace('%',''))) === 1 || 0
                  ? styles.varPositive
                  : styles.varNegative
              }`}>
              {formatNumberLocale(summary.variationAmount ? summary.variationAmount.replace('-', '') : '0', cryptoSymbol == 'BABYDOGE' ? 12 : 2) +
                " " +
                (Math.sign(parseFloat(summary.variation.replace('%',''))) === 1 || 0 ? "+" : "") +
                // formatNumberLocale(var24[1], 2) +
                // "%"
                summary.variation}
            </p>
          </div>

          <div className={styles.ctInfo}>
            <h4 className={styles.label}>
              {t("vol24") + " (" + cryptoSymbol + ")"}
            </h4>

            <p className={styles.info}>{formatNumberLocale(summary.amount, cryptoSymbol == 'BABYDOGE' ? 8 : 2)}</p>
          </div>
        </div>

        <div className={styles.ctCol}>
          <div className={styles.ctInfo}>
            <h4 className={styles.label}>{t("max24")}</h4>

            <p className={styles.info}>{formatNumberLocale(summary.high, cryptoSymbol == 'BABYDOGE' ? 12 : 2)}</p>
          </div>

          <div className={styles.ctInfo}>
            <h4 className={styles.label}>{t("min24")}</h4>

            <p className={styles.info}>{formatNumberLocale(summary.low, cryptoSymbol == 'BABYDOGE' ? 12 : 2)}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PairInfoBoxMobile;
