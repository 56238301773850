import { useDispatch, useSelector } from "react-redux";
import {
  IUserSlice,
  setAll,
  setIdConnection,
  setIdConnectionChart,
  setPair,
  signOut,
} from "../state/features/User/user.slice";
import { signOut as pairOut } from "../state/features/Pair/pair.slice";
import { signOut as userOrderOut } from "../state/features/UserOrder/userOrder.slice";
import { RootState } from "../state/store";
import ApiConfig from "../config/api-config";

export const useUser = () => {
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.User.token);

  const idConnection = useSelector(
    (state: RootState) => state.User.idConnection
  );

  const idConnectionChart = useSelector(
    (state: RootState) => state.User.idConnection
  );

  const fingerprint = useSelector((state: RootState) => state.User.fingerprint);

  const pair = useSelector((state: RootState) => state.User.pair);

  const subID = useSelector((state: RootState) => state.User.subID);

  const userID = useSelector((state: RootState) => state.User.userID);

  const sub = useSelector((state: RootState) => state.User.sub);

  const onSetAll = (value: IUserSlice) => {
    dispatch(setAll(value));
  };

  const onSetPair = (value: string) => {
    dispatch(setPair(value));
  };

  const onSetIdConnection = (value: string) => {
    dispatch(setIdConnection(value));
  };

  const onSetIdConnectionChart = (value: string) => {
    dispatch(setIdConnectionChart(value));
  };

  const onSignOut = () => {
    dispatch(signOut());
    dispatch(pairOut());
    dispatch(userOrderOut());

    window.location.assign(`${ApiConfig.PORTAL_URL}/sign-in?signout=true`)
  };

  return {
    token,
    fingerprint,
    pair,
    subID,
    userID,
    sub,
    idConnection,
    idConnectionChart,
    onSetAll,
    onSetPair,
    onSetIdConnection,
    onSetIdConnectionChart,
    onSignOut,
  };
};
