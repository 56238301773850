import React from "react";
import { formatNumberLocale } from "../../utils/stringUtils";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { usePair } from "../../hooks/usePair";
import { useSummary } from "../../hooks/useSummary";
import { useTrade } from "../../hooks/useTrade";
import { getPriceDigits } from "../../services/wsEngine";

interface IPairInfoBox {
  value: string;
  type: string;
  style?: string;
}

const PairInfoBox = ({ value, type, style }: IPairInfoBox) => {
  const { t } = useTranslation();

  const { cryptoSymbol } = usePair();
  const { assetSymbol } = usePair();
  const { summary } = useSummary();
  const { trades } = useTrade();
  value = trades[0] ? trades[0].price : '0';
  type = trades[0] ? trades[0].side : 'BUY';

  const [var24, setVar24] = React.useState(["289.60", "1.78"]);
  const [max24, setMax24] = React.useState("16548.71");
  const [min24, setMin24] = React.useState("15996.27");
  const [vol24, setVol24] = React.useState("4212172416.41");

  // const [buyOrSell, setBuyOrSell] = React.useState(type === "BUY" ? 0 : 1);

  // React.useEffect(() => {
  //   setBuyOrSell(type === "BUY" ? 1 : 0);
  // }, [type, setBuyOrSell]);

  return (
    <div className={`${styles.container} ${style}`}>
      <p className={styles.pair}>{cryptoSymbol + "/" + assetSymbol}</p>

      <div className={styles.ctNumbers}>
        <p className={type === "BUY" ? styles.valueBuy : styles.valueSell}>
          {formatNumberLocale(value, getPriceDigits(`${cryptoSymbol + "_" + assetSymbol}`))}
        </p>

        <div className={styles.ctInfo}>
          <h4 className={styles.label}>{t("var24")}</h4>
          <p
            className={`${styles.info} ${Math.sign(parseFloat(summary.variation.replace("%", ""))) === 1 ||
              0
              ? styles.varPositive
              : styles.varNegative
              }`}>
            {formatNumberLocale(
              summary.variationAmount
                ? summary.variationAmount.replace("-", "")
                : "0",
              cryptoSymbol == 'BABYDOGE' ? 12 : 2
            ) +
              " " +
              (Math.sign(parseFloat(summary.variation.replace("%", ""))) ===
                1 || 0
                ? "+"
                : "") +
              summary.variation}
          </p>
        </div>

        <div className={styles.ctInfo}>
          <h4 className={styles.label}>{t("max24")}</h4>
          <p className={styles.info}>{formatNumberLocale(summary.high, cryptoSymbol == 'BABYDOGE' ? 12 : 2)}</p>
        </div>

        <div className={styles.ctInfo}>
          <h4 className={styles.label}>{t("min24")}</h4>
          <p className={styles.info}>{formatNumberLocale(summary.low, cryptoSymbol == 'BABYDOGE' ? 12 : 2)}</p>
        </div>

        <div className={styles.ctInfo}>
          <h4 className={styles.label}>
            {t("vol24") + " (" + cryptoSymbol + ")"}
          </h4>
          <p className={styles.info}>{formatNumberLocale(summary.amount, cryptoSymbol == 'BABYDOGE' ? 8 : 2)}</p>
        </div>
      </div>
    </div>
  );
};

export default PairInfoBox;
