import { useDispatch, useSelector } from "react-redux";
import {
  setCryptoSymbol,
  setCryptoBalance,
  setAssetSymbol,
  setAssetBalance,
} from "../state/features/Pair/pair.slice";
import { RootState } from "../state/store";

export const usePair = () => {
  const dispatch = useDispatch();

  const cryptoSymbol = useSelector(
    (state: RootState) => state.Pair.crypto.symbol
  );

  const cryptoBalance = useSelector(
    (state: RootState) => state.Pair.crypto.balance
  );

  const assetSymbol = useSelector(
    (state: RootState) => state.Pair.asset.symbol
  );

  const assetBalance = useSelector(
    (state: RootState) => state.Pair.asset.balance
  );

  const onSetCryptoSymbol = (value: string) => {
    dispatch(setCryptoSymbol(value));
  };

  const onSetCryptoBalance = (value: string) => {
    dispatch(setCryptoBalance(value));
  };

  const onSetAssetSymbol = (value: string) => {
    dispatch(setAssetSymbol(value));
  };

  const onSetAssetBalance = (value: string) => {
    dispatch(setAssetBalance(value));
  };

  return {
    cryptoSymbol,
    cryptoBalance,
    assetSymbol,
    assetBalance,
    onSetCryptoSymbol,
    onSetCryptoBalance,
    onSetAssetSymbol,
    onSetAssetBalance,
  };
};
