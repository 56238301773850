import React from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import BuyAndSell from "..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useInfo } from "../../../hooks/useInfo";
import { motion, AnimatePresence } from "framer-motion";

interface IBuyAndSellModal {
  show: boolean;
  setShow: any;
  style?: string;
}

const BuyAndSellModal = ({ show, setShow, style }: IBuyAndSellModal) => {
  const { t } = useTranslation();

  const { buyOrSell, onSetBuyOrSell } = useInfo();

  return (
    <AnimatePresence>
      {show ? (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.backdrop}
            onClick={() => setShow(false)}
          />

          <motion.div
            initial={{ y: 500, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { duration: 0.75 } }}
            exit={{ y: 500, opacity: 0, transition: { duration: 0.75 } }}
            className={`${styles.container} ${style}`}>
            <div className={styles.titleRow}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faClose}
                onClick={() => setShow(false)}
                size="2x"
              />
            </div>

            <div className={styles.buttons}>
              <button
                className={
                  buyOrSell === "buy"
                    ? styles.selectedBuy
                    : styles.notSelectedButton
                }
                onClick={() => onSetBuyOrSell("buy")}>
                {t("buy")}
              </button>
              <button
                className={
                  buyOrSell === "sell"
                    ? styles.selectedSell
                    : styles.notSelectedButton
                }
                onClick={() => onSetBuyOrSell("sell")}>
                {t("sell")}
              </button>
            </div>

            <BuyAndSell />
          </motion.div>
        </>
      ) : null}
    </AnimatePresence>
  );
};

export default BuyAndSellModal;
