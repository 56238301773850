import React from "react";
import { usePair } from "../../hooks/usePair";
import styles from "./styles.module.css";

interface IOrderValuePreset {
  price: string;
  setAmount: any;
  wallet: string;
  buyOrSell: string;
  style?: string;
}

const OrderValuePreset = ({
  price,
  setAmount,
  wallet,
  buyOrSell,
  style,
}: IOrderValuePreset) => {
  const formatTo = (value: string, limit: number) => {
    value = value.replaceAll(",", ".");
    let i = value.lastIndexOf(".");

    if (i > 0) {
      let integer = value.substring(0, i).replaceAll(".", "");
      let decimals = value.substring(i + 1, value.length);
      decimals = decimals.substring(0, limit);
      value = integer + "." + decimals;
    }

    return value;
  };

  const { cryptoSymbol } = usePair();
  const [nZero, setNZero] = React.useState(
    cryptoSymbol === "BABYDOGE" ? 12 : 8
  );

  React.useEffect(() => {
    setNZero(cryptoSymbol === "BABYDOGE" ? 12 : 8);
  }, [cryptoSymbol]);

  const [filled, setFilled] = React.useState([false, false, false, false]);
  const [formatPrice, setFormatPrice] = React.useState(formatTo(price, nZero));

  React.useEffect(() => {
    setFormatPrice(formatTo(price, nZero));
  }, [price, nZero]);

  const fillButtons = (upTo: number) => {
    let aux = [false, false, false, false];

    for (let i = 0; i <= upTo; i++) {
      aux[i] = true;
    }

    setFilled(aux);
  };

  const limitTo = (rawValue: number, limit: number) => {
    let value = rawValue.toString();
    let i = value.indexOf(".");

    if (i > 0) {
      let integer = value.substring(0, i);
      let decimals = value.substring(i + 1, value.length);
      decimals = decimals.substring(0, limit);
      value = integer + "." + decimals;
    }

    return value;
  };

  const setValue = (preset: number) => {
    preset === 1
      ? setAmount(
          buyOrSell === "buy"
            ? (
                (preset * parseFloat(limitTo(parseFloat(wallet), nZero))) /
                parseFloat(formatPrice)
              ).toFixed(nZero)
            : (preset * parseFloat(limitTo(parseFloat(wallet), nZero))).toFixed(
                nZero
              )
        )
      : setAmount(
          buyOrSell === "buy"
            ? ((preset * parseFloat(wallet)) / parseFloat(formatPrice)).toFixed(
                nZero
              )
            : (preset * parseFloat(wallet)).toFixed(nZero)
        );
  };

  return (
    <div className={`${styles.container} ${style}`}>
      <button
        onClick={() => {
          fillButtons(0);
          setValue(0.25);
        }}
        className={filled[0] ? styles.buttonFilled : styles.button}>
        <h3
          className={filled[0] ? styles.buttonLabelFilled : styles.buttonLabel}>
          25%
        </h3>
      </button>
      <button
        onClick={() => {
          fillButtons(1);
          setValue(0.5);
        }}
        className={filled[1] ? styles.buttonFilled : styles.button}>
        <h3
          className={filled[1] ? styles.buttonLabelFilled : styles.buttonLabel}>
          50%
        </h3>
      </button>
      <button
        onClick={() => {
          fillButtons(2);
          setValue(0.75);
        }}
        className={filled[2] ? styles.buttonFilled : styles.button}>
        <h3
          className={filled[2] ? styles.buttonLabelFilled : styles.buttonLabel}>
          75%
        </h3>
      </button>
      <button
        onClick={() => {
          fillButtons(3);
          setValue(1);
        }}
        className={filled[3] ? styles.buttonFilled : styles.button}>
        <h3
          className={filled[3] ? styles.buttonLabelFilled : styles.buttonLabel}>
          100%
        </h3>
      </button>
    </div>
  );
};

export default OrderValuePreset;
