import React from "react";
import SwitchTabs from "../SwitchTabs";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import PairGraph from "../PairGraph";
import OrderBookMobile from "../OrderBookMobile";
import Trades from "../Trades";
import { store } from "../../state/store";

interface IMobileTabs {
  style?: string;
}
const MobileTabs = ({ style }: IMobileTabs) => {
  const { t } = useTranslation();

  const buyBookData = [
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
    { type: "buy", price: "16340.24", amount: "0.12250", total: "243.4958" },
  ];

  const sellBookData = [
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
    {
      type: "sell",
      price: "160.24",
      amount: "0.150",
      total: "23.48",
    },
  ];

  const tradesData = [
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "sell", price: "16340.24", amount: "0.12250", time: "11:09:47" },
    { type: "buy", price: "16340.24", amount: "0.12250", time: "11:09:47" },
  ];

  const tabs = [t("graphic"), t("order book"), t("trades")];
  const [selectedTab, setSelectedTab] = React.useState(0);

  return (
    <div className={`${styles.container} ${style}`}>
      <SwitchTabs
        labels={tabs}
        selected={selectedTab}
        setSelected={setSelectedTab}
        style={styles.tabs}
      />

      <div className={styles.content}>
        {selectedTab === 0 ? (
          <PairGraph style={styles.graphic} />
        ) : selectedTab === 1 ? (
          <OrderBookMobile buyData={buyBookData} sellData={sellBookData} />
        ) : (
          <Trades data={store.getState().Trade.trade} />
        )}
      </div>
    </div>
  );
};

export default MobileTabs;
