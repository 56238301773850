import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn, setBuyOrSell } from "../state/features/Info/info.slice";
import { RootState } from "../state/store";

export const useInfo = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state: RootState) => state.Info.isLoggedIn);
  const buyOrSell = useSelector((state: RootState) => state.Info.buyOrSell);

  const onSetIsLoggedIn = (value: boolean) => {
    dispatch(setIsLoggedIn(value));
  };

  const onSetBuyOrSell = (value: string) => {
    dispatch(setBuyOrSell(value));
  };

  return {
    isLoggedIn,
    buyOrSell,
    onSetIsLoggedIn,
    onSetBuyOrSell,
  };
};
