import numbro from "numbro";
import BigNumber from "bignumber.js";

let deviceLanguage: string = navigator.language;

let decimalsDelimiter = deviceLanguage === "pt-BR" ? "," : ".";
let thousandsDelimiter = deviceLanguage === "pt-BR" ? "." : ",";

numbro.setLanguage(deviceLanguage.replace("_", "-"));

export const formatNumberLocale = (
  value: number | any,
  nDecimals: number,
  limitDecimals?: number,
  nZeros?: number
) => {
  if (value === undefined || value == null) {
    value = 0;
  }

  if (nDecimals === undefined || nDecimals == null) {
    nDecimals = 2;
  }

  let numberOBJ = setCorrectDecimalsObj(
    value,
    nDecimals,
    nDecimals,
    limitDecimals,
    nZeros
  );

  value = new BigNumber(numberOBJ.numberFormatted).toFormat(20, 0, {
    decimalSeparator: decimalsDelimiter,
    groupSeparator: thousandsDelimiter,
    groupSize: 3,
  });

  value = truncateNumberString(value, nDecimals, decimalsDelimiter);

  return value;
};

export const formatAmount = (pair: string, value: string) => {
  return pair == 'BABYDOGE_BRLC' ? formatBabydoge(value) : formatNumberLocale(value, 8);
}

const formatBabydoge = (number: string) => {

}


export const getPriceDecimalsPlaces = (pair: string) => {
  return pair == 'ZAIF_BRLC' || pair == 'ZFAI_BRLC' || pair == 'B2U_BRLC' || pair == 'RACA_BRLC' ? 4 : pair == 'BABYDOGE_BRLC' ? 12 : 2;
}

export const formatNumberLocaleDefinedDecimals = (
  value: number | string | any,
  nDecimals: number
) => {
  if (value === undefined || value == null) {
    value = 0;
  }

  if (nDecimals === undefined || nDecimals == null) {
    nDecimals = 2;
  }

  let numberOBJ = { numberFormatted: value, nDecimals };

  value = new BigNumber(numberOBJ.numberFormatted).toFormat(20, 0, {
    decimalSeparator: decimalsDelimiter,
    groupSeparator: thousandsDelimiter,
    groupSize: 3,
  });

  value = truncateNumberString(value, nDecimals, decimalsDelimiter);

  return value;
};

export const formatNumberLocaleTrim = (
  value: number | string,
  nDecimals: number
) => {
  let rgx =
    deviceLanguage === "pt-BR"
      ? /(,[0-9]*[1-9])0+$|,0*$/
      : /(\.[0-9]*[1-9])0+$|\.0*$/;

  return formatNumberLocaleDefinedDecimals(value, nDecimals).replace(rgx, "$1");
};

export const formatNumberLocaleAverage = (
  value: number,
  nDecimals: number,
  limitDecimals?: number
) => {
  if (value === undefined || value == null) {
    value = 0;
  }

  if (nDecimals === undefined || nDecimals == null) {
    nDecimals = 2;
  }

  let numberOBJ = setCorrectDecimalsObj(
    value,
    nDecimals,
    nDecimals,
    limitDecimals
  );

  value = Number(numberOBJ.numberFormatted);

  return numbro(value).format({
    thousandSeparated: true,
    mantissa: numberOBJ.nDecimals,
    average: true,
  });
};

const setCorrectDecimalsObj = (
  value: number,
  nDecimals: number,
  originalsDecimals: number,
  limitDecimals?: number,
  _nZeros?: number
): any => {
  let numberFormatted = truncateDecimals(value, nDecimals);
  let numericValueConversion = Number(numberFormatted);
  let limit = limitDecimals ? limitDecimals : 13;

  if (
    numericValueConversion === 0 &&
    nDecimals < limit &&
    numericValueConversion < 1
  ) {
    return setCorrectDecimalsObj(
      value,
      nDecimals + 1,
      originalsDecimals,
      limitDecimals,
      _nZeros
    );
  }

  let nZeros = countZeros(numberFormatted);
  let nZeroTeste = _nZeros ? _nZeros : 4;

  if (Number(numberFormatted.split(".")[0]) > 0) {
    nDecimals = limitDecimals ? limitDecimals : 2;

    return { numberFormatted, nDecimals: nDecimals };
  }

  if (nZeros < nZeroTeste || limitDecimals || Number(numberFormatted) === 0) {
    if (Number(numberFormatted) === 0) {
      return { numberFormatted, nDecimals: nDecimals };
    } else {
      if (nDecimals === 8) {
        return { numberFormatted, nDecimals: nDecimals };
      }
      return { numberFormatted, nDecimals: nDecimals + 2 };
    }
  }

  numberFormatted = truncateDecimals(value, nDecimals + 4);
  return { numberFormatted, nDecimals: nDecimals + 4 };
};

export const truncateDecimals = (number: number, precision?: number) => {
  precision = precision ? precision : 8;

  let numberReady =
    Math.trunc(number * Math.pow(10, precision)) / Math.pow(10, precision);

  return numberReady.toFixed(precision);
};

export const truncateNumberString = (
  value: string,
  precision: number,
  separator: string = decimalsDelimiter
) => {
  return (
    value?.split(separator)[0] +
    separator +
    value?.split(separator)[1]?.substring(0, precision)
  );
};

const countZeros = (value: string) => {
  if (!value) {
    value = "0.00";
  }

  let valueReady = value.split(".")[1].split("") || "";
  let count = 0;
  let control = 1;

  valueReady.forEach((c) => {
    if (c === "0" && control === 1) {
      count++;
    } else {
      control++;
    }
  });

  return count;
};
