import React from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import ApiConfig from "../../config/api-config";
import { useUser } from "../../hooks/useUser";

interface ISideMenu {
  show: boolean;
  setShow: any;
  style?: string;
}

const SideMenu = ({ show, setShow, style }: ISideMenu) => {
  const { t } = useTranslation();

  const { onSignOut } = useUser();

  const signOut = () => {
    onSignOut();

    setShow(false);
  };

  return (
    <AnimatePresence>
      {show ? (
        <motion.div
          initial={{ x: 500, opacity: 0 }}
          animate={{ x: 0, opacity: 1, transition: { duration: 0.75 } }}
          exit={{ x: 500, opacity: 0, transition: { duration: 0.75 } }}
          className={`${styles.container} ${style}`}>
          <div className={styles.titleRow}>
            <FontAwesomeIcon
              className={styles.closeIcon}
              icon={faClose}
              onClick={() => setShow(false)}
              size="2x"
            />
          </div>

          <div className={styles.ctMenu}>
            <a className={styles.menuItem} href={ApiConfig.PORTAL_URL}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faBriefcase}
                size="xl"
                color="rgb(37, 150, 190)"
              />
              {t("home")}
            </a>

            <a
              className={styles.menuItem}
              href={`${ApiConfig.PORTAL_URL}/depositar`}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faQrcode}
                size="xl"
                color="rgb(37, 150, 190)"
              />
              {t("deposit")}
            </a>

            <a
              className={styles.menuItem}
              href={`${ApiConfig.PORTAL_URL}/transferir`}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faPaperPlane}
                size="xl"
                color="rgb(37, 150, 190)"
              />
              {t("withdraw")}
            </a>

            <a
              className={styles.menuItem}
              href={`${ApiConfig.PORTAL_URL}/extrato`}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faFileContract}
                size="xl"
                color="rgb(37, 150, 190)"
              />
              {t("extract")}
            </a>

            <a
              className={styles.menuItem}
              href={`${ApiConfig.PORTAL_URL}/subaccounts`}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faUser}
                size="xl"
                color="rgb(37, 150, 190)"
              />
              {t("sub acc")}
            </a>

            <a
              className={styles.menuItem}
              href={`${ApiConfig.PORTAL_URL}/investir`}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faCartShopping}
                size="xl"
                color="rgb(37, 150, 190)"
              />
              {t("swap")}
            </a>

            <p className={styles.menuItem} onClick={() => signOut()}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faRightFromBracket}
                size="xl"
                color="rgb(37, 150, 190)"
              />
              {t("sign out")}
            </p>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

export default SideMenu;
