

import io, { Socket } from 'socket.io-client'
import ApiConfig from '../config/api-config';
import { useUser } from '../hooks/useUser';
import { setAskBook, setBidBook } from '../state/features/Book/book.slice';
import { notifyAskOrderBook, notifyBidOrderBook, notifyMarketSummary, notifyTrades, notifyUserOrder } from './advanced';
import { getApiClientEngine } from './clientEngine';
import { store } from "../state/store";
import { setIdConnection } from '../state/features/User/user.slice';
import { useState } from 'react';
import { ITradesData, setTrade } from '../state/features/Trade/trade.slice';
import { setAssetBalance, setCryptoBalance } from '../state/features/Pair/pair.slice';
import { IUserOrderData, setExecutedOrders, setOpenOrders, setTradeOrders } from '../state/features/UserOrder/userOrder.slice';
import { fireEvent } from '@testing-library/react';

export let socketGlobal: Socket;
let interval: any = null;
// let idConnection: string | null;
// export var askBook: IBookData[] = [];
// export function getAskBook() {
//   return askBook;
// }

// export function getBidBook() {
//   return bidBook;
// }


// export var bidBook: IBookData[] = [];

export var trades: ITradesData[] = [];


// export var userOpenOrders: IBookData[] = [];
// export var userExecutedOrders: IBookData[] = [];

// exportaskBook

// export getBidBook = () => {
//   return bidBook;
// }


interface IMessageInitWs {
  method: string;
  pair: string;
  token?: string;
  origem: string;
  fingerprint?: string;
}

interface IResponsePortifolioData {
  amount: string;
  bitPoupeAmount: string;
  pendingAmount: string;
  favorite: boolean;
  id: string;
  symbol: string;
  name: string;
  status: string;
  type: string;
  decimalPlaces: number;
  dateCreated: string;
  dateUpdated: string;
  withdrawMin: string;
  operator: string;
  checkprice_tol: string;
  withdraw: number;
  depositConfirmation: string;
  buy: number;
  sell: number;
}

export const createWs = (url: string) => {
  console.log('chegou aqui')
  let socket = io(url, {
    path: '/api/v2/ws',
    transports: ['websocket']
  })
  return socket;
};

export const initWsEngine = async () => {
  let token = store.getState().User.token;
  let fingerprint = store.getState().User.fingerprint;
  let pair = store.getState().User.pair;
  // let token: string = token;
  // let fingerprint: string = store.getState().loginReducer.fingerprint;
  // let pair: string = store.getState().loginReducer.pair;

  // let token: string = ApiConfig.tokenTeste;
  // let pair: string = "BTC_BRLC";

  if (token == null || pair == null) {
    return;
  }
  socketGlobal = createWs(ApiConfig.PUBLIC_WEBSOCKET);

  socketGlobal.on('message', (ev: any) => {
    // if (ev && ev.type == "message") {
    processWsMessage(ev);
    // }
  });

  socketGlobal.on('error', () => {
    // let allReducers: IAllReducers = store.getState() as IAllReducers;
    // setTimeout(() => {
    //   if (allReducers.loginReducer.isLoggedIn) {
    if (!socketGlobal || !socketGlobal.connected) {
      initWsEngine();
    }
    //   }
    // }, 10000);
  });

  socketGlobal.on('connected', () => {
    console.log("OPEN WS ENGINE!!!", socketGlobal.connected);

    let messageObj: IMessageInitWs = {
      method: "pairSubscribe",
      pair: pair,
      origem: "WEB",
      token: token,
      fingerprint,
    };

    if (socketGlobal && socketGlobal.connected) {
      socketGlobal.send(JSON.stringify(messageObj));
    }
    interval = setInterval(() => {
      if (socketGlobal && socketGlobal.connected) {
        socketGlobal.send(JSON.stringify({ ping: "." }));
      }
    }, 30000);
  });
};

export const stopWsEngine = () => {
  socketGlobal.close();
  clearInterval(interval);
};

export const loadAdvanced = () => {
  let pair = store.getState().User.pair;
  let token = store.getState().User.token;
  let idConnection = store.getState().User.idConnection;

  let body = {
    pair: pair,
    idConnection: idConnection,
    connectionID: idConnection
  }
  console.log(body);
  notifyTrades(body);
  // notifyMarketSummary(body);

  notifyAskOrderBook(body);
  notifyBidOrderBook(body);

  if (token) {
    notifyUserOrder(body);
    notifyUserBalance(pair || "B2U_BRLC");
  }
}

const processWsMessage = (ev: any) => {
  // const { token, fingerprint, pair, idConnection, onSetIdConnection } = useUser();
  //if (store.getState().userReducer.onDrag) {
  //return;
  //}

  // let pair: string = pair;
  let temp = null;
  try {
    temp = JSON.parse(ev);
  } catch (error) {
    return;
  }


  if (temp.connectionID) {

    if (temp.status == "200") {
      store.dispatch(setIdConnection(temp.connectionID));
      // notifyUserBalance(pair || "BTC_BRLC");
      // notifyPortifolioData();
      if (temp.text == "subscribe with sucess")
        loadAdvanced();
    }
  }

  if (temp.length) {
    setBalancePairData(temp);
  }

  switch (temp.method) {
    case "portifolioDataUpdate":
      setStatePortifolioData(temp.data);
      break;
    case 'trades':
      console.log(temp);
      sendTradeData(temp.list);
      break;
    case 'newTradeOrder':
      setNewTradeOrder(temp.orders);
      break;
    // case 'easy':
    //   console.log(temp);
    //   this.sendEasyData(temp.credit);
    //   break;
    // case 'easyData':
    //   console.log(temp);
    //   this.sendEasyUserData(temp.verify);
    //   break;
    case 'changeOrderBookv2':
      // console.log(temp);
      setChangeOrderBook(temp);
      break;
    case 'bidOrderBookv2':
      console.log(temp);
      setBidOrderBookData(temp.list);
      break;
    case 'askOrderBookv2':
      console.log(temp);
      setAskOrderBookData(temp.list);
      break;
    // case 'summary':
    //   setMarketSummaryData(temp.list);
    //   break;
    case 'notifyOrder':
      // console.log(temp);
      let m_orders: any = {};
      m_orders.tradeList = temp.tradeList;
      m_orders.canceledList = temp.canceledList;
      m_orders.executedList = temp.executedList;
      m_orders.list = temp.list;
      sendOrdersData(m_orders);
      break;
    case 'userOrderUpdated':
      // console.log(temp);
      setUserTradeUpdated(temp.order);
      break;
    case 'userDadUpdated':
      console.log(temp);
      setUserOrderUpdated(temp.order);
      break;
    case 'userOrderCancelled':
      // console.log(temp);
      setUserTradeCancelled(temp.order);
      break;

    case 'userDadCancelled':
      // console.log(temp);
      setUserOrderCancelled(temp.order);
      break;
    case 'newDadOrder':
      console.log(temp)
      setUserOrderUpdated(temp.order);
      break;
    case 'newUserOrder':
      // console.log(temp)
      setUserTradeUpdated(temp.order);
      // setUserOrderUpdated(temp.order);
      break;
    // case 'updateBidOrder':
    //   this.setUpdatedBidOrder(temp);
    //   break;
    // case 'updateAskOrder':
    //   this.setUpdatedAskOrder(temp);
    //   break;
    // case 'portifolioDataUpdate':
    //   this.notifyPortifolioDataObs(temp);
    //   break;
    // case 'notifyUserBalanceTotal':
    //   this.sendUpdateBalanceTotal(e);
    //   break;
  }
};

export const notifyUserBalance = async (pair: string) => {
  let clientEngine = await getApiClientEngine();
  let body = {
    pair: pair,
  };

  clientEngine
    .post(`/balance/notifyUserBalance`, body)
    .then((result) => {
      console.log("notify user balance");
    })
    .catch((err) => {
      console.log(
        "error notify user balance: "
        // JSON.stringify(Object.keys(err.response.headers))
      );
    });
};

///order/notifyPortifolioData

const sendTradeData = (data: ITradesData[]) => {
  store.dispatch(setTrade(data));
};

export const notifyPortifolioData = async () => {
  // const { token, fingerprint, pair, idConnection, onSetIdConnection } = useUser();
  let idConnection = store.getState().User.idConnection;
  let clientEngine = await getApiClientEngine();
  let body = {
    connectionID: idConnection,
  };

  clientEngine
    .post(`/order/notifyPortifolioData`, body)
    .then((result) => {
      console.log("notify PortifolioData");
    })
    .catch((err) => {
      console.log(
        "error PortifolioData: "
        // JSON.stringify(Object.keys(err.response.headers))
      );
    });
};


// function setMarketSummaryData(data: any) {
//   console.log(data);
//   data.forEach(() => {

//   })
// }

const setBalancePairData = (data: any[]) => {
  // console.log(data);
  let pair = store.getState().User.pair;
  let crypto = pair.split('_')[0];
  let coin = pair.split('_')[1];
  data.forEach((balance) => {
    if (balance.asset == crypto)
      store.dispatch(setCryptoBalance(balance.amount));
    else if (balance.asset == coin)
      store.dispatch(setAssetBalance(balance.amount));
  })

}

const setStatePortifolioData = (data: IResponsePortifolioData[]) => {
  // try {
  //   let assetsSell: any[] = [];
  //   data.forEach((d: IResponsePortifolioData) => {
  //     let priceAndSaldo: IBalanceData = {};

  //     priceAndSaldo.amountCripto =
  //       Number(d.amount) - Number(d.bitPoupeAmount) - Number(d.pendingAmount);
  //     priceAndSaldo.amountCriptoExib = priceAndSaldo.amountCripto.toFixed(8);
  //     priceAndSaldo.amountDescBitPoupe =
  //       Number(d.amount) - Number(d.bitPoupeAmount);
  //     priceAndSaldo.bitPoupeAmount = Number(d.bitPoupeAmount);
  //     priceAndSaldo.favorite = d.favorite;
  //     priceAndSaldo.pendingAmount = Number(d.pendingAmount);
  //     priceAndSaldo.show = d.favorite;
  //     priceAndSaldo.symbolAsset = d.symbol;

  //     store.dispatch(setBalanceData(d.symbol, priceAndSaldo));

  //     if (
  //       Number(d.amount) > 0 &&
  //       d.symbol !== "BRL" &&
  //       d.symbol !== "EUR" &&
  //       d.symbol !== "CBRL"
  //     ) {
  //       assetsSell.push({ asset: d.symbol, amount: Number(d.amount) });
  //     }
  //     // console.log('SAVE SALDO IN OBJECT')
  //   });

  //   assetsSell = assetsSell.sort((a, b) => {
  //     return b.amount - a.amount;
  //   });

  //   assetsSell = assetsSell.map((c) => {
  //     return c.asset;
  //   });

  //   store.dispatch(setSellAssets(assetsSell));
  // } catch (error) {

  // }

};

export const unSubscribePair = (pair: string) => {
  let pairUnsubscribe = {
    method: "pairUnsubscribe",
    pair: pair,
  };
  if (socketGlobal && socketGlobal.connected) {
    socketGlobal.send(JSON.stringify(pairUnsubscribe));
  }
};

export const subscribePair = (pairNew: string) => {
  // let idConnection = store.getState().User.idConnection;
  // const { token, fingerprint, pair, idConnection, onSetIdConnection } = useUser();
  let token: string = store.getState().User.token;
  let fingerprint: string = store.getState().User.fingerprint;
  // store.dispatch(setPair(pair));

  let messageObj: IMessageInitWs = {
    method: "pairSubscribe",
    pair: pairNew,
    origem: "APP",
    token: token,
    fingerprint,
  };
  if (socketGlobal && socketGlobal.connected) {
    socketGlobal.send(JSON.stringify(messageObj));
  }
};


function setNewTradeOrder(orders: ITradesData[]) {
  // return;
  // console.log(orders);
  let tradesData: ITradesData[] = [...store.getState().Trade.trade];
  tradesData = tradesData.concat(orders);
  tradesData.sort((a, b) => {
    return new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime();
  })
  store.dispatch(setTrade(tradesData));
}

function sendOrdersData(ordersLists: any) {
  // userOpenOrders = ordersLists.list;
  // userExecutedOrders = ordersLists.executedList;
  console.log('aquuuuuiii')
  store.dispatch(setExecutedOrders(ordersLists.executedList));
  store.dispatch(setOpenOrders(ordersLists.list));
  store.dispatch(setTradeOrders(ordersLists.tradeList));
}



function setBidOrderBookData(ordersBid: any[]) {
  // console.log(ordersBid.slice(0, 30));
  // bidBook = ordersBid;
  store.dispatch(setBidBook(ordersBid.slice(0, 20)));
  // store.dispatch(setBuyPrice(ordersBid[0] ? Number(ordersBid[0].price) : 0));
  // let pair: string = store.getState().loginReducer.pair;
  // let bidOrders: any[] = [];
  // let bidView: any[] = [];
  // // store.dispatch(setBidOrder(ordersBid));
  // ordersBid.forEach(o => {

  //   let orderGroup: any = {
  //     price: '',
  //     amount: '',
  //     orders: [],
  //     hasSubID: false,
  //     amountWithFormatter: "",
  //   }

  //   o.amount = Number(o.amount).toFixed(8);
  //   o.price = Number(o.price).toFixed(getPriceDigits(pair));

  //   let index = bidOrders.findIndex(e => e.price == o.price);


  //   if (index > -1) {
  //     bidOrders[index].orders.push({ id: o.id, amount: o.amount });
  //     bidOrders[index].amount = Number(bidOrders[index].amount) + Number(o.amount);
  //     // bidOrders[index].amountWithFormatter = 
  //     bidView[index].amountWithFormatter = nFormatter(bidOrders[index].amount, 8)
  //     // bidOrders[index].hasSubID = isFromSub(o.id) ? true : bidOrders[index].hasSubID;
  //   } else {
  //     // orderGroup.userID = o.userID;

  //     orderGroup.price = o.price;
  //     orderGroup.amount = o.amount
  //     // orderGroup.amountWithFormatter = nFormatter(o.amount, 8);
  //     // orderGroup.subID = o.subID;
  //     // orderGroup.hasSubID = isFromSub(o.id) ? true : orderGroup.hasSubID;
  //     orderGroup.orders.push({ id: o.id, amount: o.amount });
  //     bidOrders.push(orderGroup);
  //     bidView.push({ price: orderGroup.price, amountWithFormatter: nFormatter(o.amount, 8) });
  //   }

  // })
  // // bidBook = bidView;
  // // bidMounted = bidOrders;
  // store.dispatch(setBidBook(bidView));
  // store.dispatch(setBuyPrice(bidView[0] ? Number(bidView[0].price) : 0));
}

function setChangeOrderBook(temp: any) {
  let askBook = [...store.getState().Book.ask];
  let bidBook = [...store.getState().Book.bid];
  // let askView: IBookView[] = store.getState().advancedReducer.askBook;
  // // let askOrders: IOrderBook[] = askMounted;
  // // let bidOrders: IOrderBook[] = bidMounted;
  // let bidView: IBookView[] = store.getState().advancedReducer.bidBook;
  // store.dispatch(setAskOrder(ordersAsk));
  // console.log(JSON.stringify(ordersAsk));
  for (let i = 0; i < temp.changes.length; i++) {
    let type = temp.changes[i].type;
    let order = temp.changes[i].order;
    if (type == 'ua') {
      let idx = askBook.findIndex(e => e.price == order.price);
      if (idx >= 0) {
        askBook[idx] = order;
      } else {
        askBook.push(order);
      }
    } else if (type == 'ub') {
      let idx = bidBook.findIndex(e => e.price == order.price);
      if (idx >= 0) {
        bidBook[idx] = order;
      } else {
        bidBook.push(order);
      }
    } else if (type == 'ra') {
      let idx = askBook.findIndex(e => e.price == order.price);
      if (idx >= 0) {
        askBook.splice(idx, 1);
      }
    } else if (type == 'rb') {
      let idx = bidBook.findIndex(e => e.price == order.price);
      if (idx >= 0) {
        bidBook.splice(idx, 1);
      }
    }

  };

  askBook.sort((a, b) => {
    return Number(b.price) - Number(a.price);
  });

  bidBook.sort((a, b) => {
    return Number(b.price) - Number(a.price);
  });

  store.dispatch(setAskBook(askBook));
  store.dispatch(setBidBook(bidBook));
  // store.dispatch(setAskBook(askView));
  // store.dispatch(setBidBook(bidView));
  // askMounted = askOrders;
  // bidMounted = bidOrders;
  // store.dispatch(setSellPrice(askView[0] ? Number(askView[0].price) : 0));
  // store.dispatch(setBuyPrice(bidView[0] ? Number(bidView[0].price) : 0));
  // store.dispatch(setRefreshedBook());
}

function getSomatorio(orders: any[]) {
  let soma = 0;
  // orders.forEach((or: IOrderBook) => {
  //   soma = Number(soma) + Number(or.amount)
  // });


  return soma;
}

function setAskOrderBookData(ordersAsk: any[]) {
  // console.log(ordersAsk.slice(0, 30));
  // store.dispatch(setLoaded());
  // askBook = ordersAsk.reverse();
  store.dispatch(setAskBook(ordersAsk.slice(0, 20).reverse()));
  // store.dispatch(setAskBook(ordersAsk.slice(0, 20)));
  // store.dispatch(setSellPrice(ordersAsk[0] ? Number(ordersAsk[0].price) : 0));
  // store.dispatch(setRefreshedBook());
  // store.dispatch(setLoaded());
  // let pair: string = store.getState().loginReducer.pair;
  // let askOrders: any[] = [];
  // let askView: IBookView[] = []
  // // store.dispatch(setAskOrder(ordersAsk));
  // // console.log(JSON.stringify(ordersAsk));


  // ordersAsk.forEach(o => {

  //   let orderGroup: any = {
  //     price: '',
  //     amount: '',
  //     orders: [],
  //     // userID: false,
  //     // subID: null,
  //     hasSubID: false,
  //     amountWithFormatter: "",
  //   }

  //   o.amount = Number(o.amount).toFixed(8);
  //   o.price = Number(o.price).toFixed(getPriceDigits(pair));

  //   let index = askOrders.findIndex(e => e.price == o.price);

  //   if (index > -1) {
  //     // askOrders[index].userID = o.userID;
  //     // askOrders[index].subID = o.subID;
  //     askOrders[index].orders.push({ id: o.id, amount: o.amount });

  //     askOrders[index].amount = Number((askOrders[index].amount)) + Number(o.amount);
  //     // askOrders[index].hasSubID = isFromSub(o.id) ? true : askOrders[index].hasSubID
  //     askView[index].amountWithFormatter = nFormatter(askOrders[index].amount, 8)

  //   } else {
  //     // orderGroup.userID = o.userID;
  //     // orderGroup.subID = o.subID;
  //     orderGroup.price = o.price;
  //     orderGroup.amount = o.amount
  //     orderGroup.amountWithFormatter = nFormatter(o.amount, 8)

  //     // orderGroup.hasSubID = this.isFromSub(o.id) ? true : orderGroup.hasSubID;
  //     orderGroup.orders.push({ id: o.id, amount: o.amount });
  //     askOrders.push(orderGroup);
  //     askView.push({ price: orderGroup.price, amountWithFormatter: nFormatter(o.amount, 8) })
  //   }
  // })
  // // askMounted = askOrders;
  // // askBook = askView;
  // store.dispatch(setAskBook(askView));
  // store.dispatch(setSellPrice(askView[0] ? Number(askView[0].price) : 0));
  // store.dispatch(setRefreshedBook());
}

export function getPriceDigits(pair: string) {
  return pair == 'ZAIF_BRLC' || pair == 'ZFAI_BRLC' || pair == 'B2U_BRLC' || pair == 'RACA_BRLC' ? 4 : pair == 'BABYDOGE_BRLC' ? 12 : 2;
}

function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  var item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });

  if (!item) {
    return num ? limitTo(Number(num).toFixed(12), digits) : '0.00000000';
  } else

    try {
      const numberConverted = (num / item.value).toFixed(item?.symbol ? 2 : digits).replace(rx, "$1")

      try {
        let numCases = String(numberConverted).split(".")

        const floatNumLength = numCases[1].length
        const numberForComplete = (item?.symbol ? 2 : digits) - floatNumLength

        if (numberForComplete < 0) {
          return item ? (numberConverted + item.symbol).replace(".", ",") : "0";
        }

        return item ? (numberConverted + "0".repeat(numberForComplete)).replace(".", ",") + item.symbol : "0";
      } catch {
        return item ? numberConverted + `,${"0".repeat(item?.symbol ? 2 : digits)}` + item.symbol : "0";
      }
    } catch (err) {
      // console.log(err)
      return num ? limitTo(Number(num).toFixed(12), digits) : '0.00000000';
    }
}

function limitTo(number: any, idx: number) {
  number = number.toString();
  let i = number.indexOf('.')
  if (i > 0) {
    let integer = number.substring(0, i);
    let decimals = number.substring(i + 1, number.length)
    decimals = decimals.substring(0, idx)
    number = integer + '.' + decimals
  }
  return number;
}

function sortArray(array: any[]) {
  return array.sort((a, b) => {
    return new Date(a.dateUpdated).getTime() - new Date(b.dateUpdated).getTime();
  }).reverse();
}

function setUserOrderUpdated(order: IUserOrderData) {
  console.log(order)
  let orders: IUserOrderData[] = store.getState().UserOrder.openOrders;
  // let executedOrders: IOrderUser[] = store.getState().advancedReducer.executedOrders;
  if (order.status === 'OPEN' || order.status === "STOPPED") {
    if (orders) {
      orders = orders.filter(or => {
        return or.orderID !== order.orderID;
      });
    } else {
      orders = [];
    }
    orders.push(order);
    orders = sortArray(orders);
    // userOpenOrders = orders;
    store.dispatch(setOpenOrders(orders));
  } else if (order.status === 'EXECUTED' || order.status === 'CANCELED') {
    // let executedOrders: IOrderUser[] = userExecutedOrders;
    let executedOrders: IUserOrderData[] = store.getState().UserOrder.executedOrders;
    if (executedOrders && executedOrders.length > 0) {
      executedOrders = executedOrders.filter(or => {
        return or.orderID !== order.orderID;
      });
    } else {
      executedOrders = [];
    }
    executedOrders.push(order);
    executedOrders = sortArray(executedOrders);

    if (orders) {
      orders = orders.filter(or => {
        return or.orderID !== order.orderID;
      });
    }
    // userOpenOrders = orders;
    // userExecutedOrders = executedOrders;F
    store.dispatch(setExecutedOrders(executedOrders));
    store.dispatch(setOpenOrders(orders));
  }
}


function setUserTradeUpdated(order: IUserOrderData) {
  console.log(order)
  let orders: IUserOrderData[] = store.getState().UserOrder.tradeOrders;
  // let executedOrders: IOrderUser[] = store.getState().advancedReducer.executedOrders;
  if (orders) {
    orders = orders.filter(or => {
      return or.orderID !== order.orderID;
    });
  } else {
    orders = [];
  }
  orders.push(order);
  orders = sortArray(orders);
  // userOpenOrders = orders;
  store.dispatch(setTradeOrders(orders));
}


function setUserOrderCancelled(order: IUserOrderData) {
  let orders: IUserOrderData[] = store.getState().UserOrder.openOrders;
  // let orders: IOrderUser[] = userOpenOrders;
  orders = orders.filter(or => {
    return or.orderID !== order.orderID;
  });
  // userOpenOrders = orders;
  store.dispatch(setOpenOrders(orders));
}

function setUserTradeCancelled(order: IUserOrderData) {
  let orders: IUserOrderData[] = store.getState().UserOrder.tradeOrders;
  // let orders: IOrderUser[] = userOpenOrders;
  orders = orders.filter(or => {
    return or.orderID !== order.orderID;
  });
  // userOpenOrders = orders;
  store.dispatch(setTradeOrders(orders));
}
/*{
  "amount":"0.0023",
  "bitPoupeAmount":"0",
  "pendingAmount":"0.00",
  "favorite":true,
  "id":"10e3d84e-abd3-4431-b7c7-8b8fc4958abf",
  "symbol":"COMP",
  "name":"Compound",
  "status":"ACTIVE",
  "type":"BINANCE",
  "decimalPlaces":5,
  "dateCreated":"2020-08-06T00:07:33.000Z",
  "dateUpdated":"2020-08-06T00:07:33.000Z",
  "withdrawMin":"0.44000000","operator":
  "90aea564-602d-4776-899d-ad7ad2232a62",
  "checkprice_tol":"0.02000000",
  "withdraw":1,
  "depositConfirmation":"12",
  "buy":1,
  "sell":1} 
*/
