import React from "react";
import { useBook } from "../../hooks/useBook";
import { useBuy } from "../../hooks/useBuy";
import { useSell } from "../../hooks/useSell";
import styles from "./styles.module.css";

interface ISwitchTabs {
  labels: string[];
  selected: number;
  setSelected: any;
  style?: string;
}

export const SwitchTabs = ({
  labels,
  selected,
  setSelected,
  style,
}: ISwitchTabs) => {

  const { buyMarketPrice, onSetPriceMarketBuy } = useBuy();

  const { sellMarketPrice, onSetPriceMarketSell } = useSell();
  const { askBook, bidBook } = useBook();


  const setTab = (idx: any) => {
    if (idx == 1) {
      onSetPriceMarketBuy(askBook && askBook[0] ? askBook[0].price.toString() : '0');
      onSetPriceMarketSell(bidBook && bidBook[0] ? bidBook[0].price.toString() : '0');
    } else if (idx == 2) {

    }

    setSelected(idx)
  }


  return (
    <div className={`${styles.container} ${style}`}>
      {labels.map((label: string, index: number) => {
        return (
          <button
            className={styles.tab}
            onClick={() => setTab(index)}
            style={{ width: `${100 / labels.length}%` }}>
            <h3
              className={
                selected === index ? styles.selectedLabel : styles.label
              }>
              {label}
            </h3>
          </button>
        );
      })}
    </div>
  );
};

export default SwitchTabs;
