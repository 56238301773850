import { useDispatch, useSelector } from "react-redux";
import {
  setPriceLimitSell,
  setAmountLimitSell,
  setTotalLimitSell,
  setPriceMarketSell,
  setAmountMarketSell,
  setTotalMarketSell,
  setStopPriceStopSell,
  setLimitPriceStopSell,
  setAmountStopSell,
  setTotalStopSell,
} from "../state/features/Sell/sell.slice";
import { RootState } from "../state/store";

export const useSell = () => {
  const dispatch = useDispatch();

  const sellLimitPrice = useSelector(
    (state: RootState) => state.Sell.limit.price
  );

  const sellLimitAmount = useSelector(
    (state: RootState) => state.Sell.limit.amount
  );

  const sellLimitTotal = useSelector(
    (state: RootState) => state.Sell.limit.totalOrder
  );

  const sellMarketPrice = useSelector(
    (state: RootState) => state.Sell.market.price
  );

  const sellMarketAmount = useSelector(
    (state: RootState) => state.Sell.market.amount
  );

  const sellMarketTotal = useSelector(
    (state: RootState) => state.Sell.market.totalOrder
  );

  const sellStopPrice = useSelector(
    (state: RootState) => state.Sell.stop.stopPrice
  );

  const sellStopLimitPrice = useSelector(
    (state: RootState) => state.Sell.stop.limitPrice
  );

  const sellStopAmount = useSelector(
    (state: RootState) => state.Sell.stop.amount
  );

  const sellStopTotal = useSelector(
    (state: RootState) => state.Sell.stop.totalOrder
  );

  const onSetPriceLimitSell = (value: string) => {
    dispatch(setPriceLimitSell(value));
  };

  const onSetAmountLimitSell = (value: string) => {
    dispatch(setAmountLimitSell(value));
  };

  const onSetTotalLimitSell = (value: string) => {
    dispatch(setTotalLimitSell(value));
  };

  const onSetPriceMarketSell = (value: string) => {
    dispatch(setPriceMarketSell(value));
  };

  const onSetAmountMarketSell = (value: string) => {
    dispatch(setAmountMarketSell(value));
  };

  const onSetTotalMarketSell = (value: string) => {
    dispatch(setTotalMarketSell(value));
  };

  const onSetStopPriceStopSell = (value: string) => {
    dispatch(setStopPriceStopSell(value));
  };

  const onSetLimitPriceStopSell = (value: string) => {
    dispatch(setLimitPriceStopSell(value));
  };

  const onSetAmountStopSell = (value: string) => {
    dispatch(setAmountStopSell(value));
  };

  const onSetTotalStopSell = (value: string) => {
    dispatch(setTotalStopSell(value));
  };

  return {
    onSetPriceLimitSell,
    onSetAmountLimitSell,
    onSetTotalLimitSell,
    onSetPriceMarketSell,
    onSetAmountMarketSell,
    onSetTotalMarketSell,
    onSetStopPriceStopSell,
    onSetLimitPriceStopSell,
    onSetAmountStopSell,
    onSetTotalStopSell,
    sellLimitPrice,
    sellLimitAmount,
    sellLimitTotal,
    sellMarketPrice,
    sellMarketAmount,
    sellMarketTotal,
    sellStopPrice,
    sellStopLimitPrice,
    sellStopAmount,
    sellStopTotal,
  };
};
