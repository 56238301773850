import { useDispatch, useSelector } from "react-redux";
import { setTrade, ITradesData } from "../state/features/Trade/trade.slice";
import { RootState } from "../state/store";

export const useTrade = () => {
  const dispatch = useDispatch();

  const trades = useSelector((state: RootState) => state.Trade.trade);

  const onSeTrades = (value: ITradesData[]) => {
    dispatch(setTrade(value));
  };

  return {
    trades,
    onSeTrades,
  };
};
