import axios from 'axios';
import configAPI from '../config/api-config';
import { store } from "../state/store";

const getApiClientChart = async () => {
  let token: string = store.getState().User.token;
  let fingerprint: string = store.getState().User.fingerprint;
  // if(!token) {
    // token = ApiConfig.tokenTeste;
    // token = store.getState().loginReducer.token;
    // fingerprint = store.getState().loginReducer.fingerprint;
  // }

  // if(!fingerprint) {
  //   fingerprint = await getUniqueId();
  // }

  const apiClientChart = axios.create({
    baseURL: configAPI.API_CHART_URL,
    responseType: 'json',
    withCredentials: false,
  });

  apiClientChart.interceptors.request.use((config: any) => {
    // if (token) {
    //   if (config.method !== 'OPTIONS') {
    //     config.headers.Authorization = token;
    //     config.headers.fingerprint = fingerprint;
    //   }
    // }
    return config;
  });

  return apiClientChart;

}

export { getApiClientChart };
