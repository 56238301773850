import { Bar } from "../charting_library/charting_library";
import { candleChartData$, notifyChart } from "./wsChart";

var historyVar: any;

var resolutionVar: any;
var symbolInfoVar: any;

interface IBar {
    time: number;
    low: any;
    high: any;
    open: any;
    close: any;
    volume: number;
}

function getHistoryVar(){
    return historyVar;
}

function getBars(symbolInfo: any, resolution: any, from: any, to: any) {
    // console.log({qs})
    // return Promise.resolve().then(()=>{
    //     return [];
    // });
    // console.log(symbolInfo);
    return new Promise((resolve: (value?: any[]) => void, reject) => {
        candleChartData$.subscribe((data: any[]) => {
            let bars: Bar[] = [];
            if (data.length) {
                // console.log(`Actually returned:${JSON.stringify(data[0])}`)
                // console.log(`Actually returned: ${new Date(data.TimeFrom * 1000).toISOString()} - ${new Date(data.TimeTo * 1000).toISOString()}`)
                bars = data.map(el => {
                    return {
                        time: new Date(el.date).getTime(), //TradingView requires bar time in ms
                        low: el.low,
                        high: el.high,
                        open: el.open,
                        close: el.close,
                        volume: Number(el.volume)
                    }
                })
                var lastBar = bars[bars.length - 1]
                historyVar = { lastBar: lastBar };
                resolve(bars);
            } else {
                resolve(bars);
            }
        });
        if (resolutionVar != resolution || symbolInfoVar != symbolInfo) {
            symbolInfoVar = symbolInfo;
            resolutionVar = resolution;
            notifyChart(symbolInfo.name, resolution);
        } else {
            resolve([]);
        }
    });


}
export const clientHistory = {
    getBars,
    getHistoryVar
};

// }
