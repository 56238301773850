import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISellSlice {
  limit: ILimitOrder;
  market: IMarketOrder;
  stop: IStopOrder;
}

export interface ILimitOrder {
  price: string;
  amount: string;
  totalOrder: string;
}

export interface IMarketOrder {
  price: string;
  amount: string;
  totalOrder: string;
}

export interface IStopOrder {
  stopPrice: string;
  limitPrice: string;
  amount: string;
  totalOrder: string;
}

const INITIAL_STATE: ISellSlice = {
  limit: {
    amount: "0",
    price: "0",
    totalOrder: "0",
  },

  market: {
    amount: "0",
    price: "0",
    totalOrder: "0",
  },

  stop: {
    stopPrice: "0",
    limitPrice: "0",
    amount: "0",
    totalOrder: "0",
  },
};

export const sellSlice = createSlice({
  name: "sell",
  initialState: INITIAL_STATE,
  reducers: {
    setPriceLimitSell: (state, action: PayloadAction<string>) => {
      state.limit.price = action.payload;
      return state;
    },

    setAmountLimitSell: (state, action: PayloadAction<string>) => {
      state.limit.amount = action.payload;
      return state;
    },

    setTotalLimitSell: (state, action: PayloadAction<string>) => {
      state.limit.totalOrder = action.payload;
      return state;
    },

    setPriceMarketSell: (state, action: PayloadAction<string>) => {
      state.market.price = action.payload;
      return state;
    },

    setAmountMarketSell: (state, action: PayloadAction<string>) => {
      state.market.amount = action.payload;
      return state;
    },

    setTotalMarketSell: (state, action: PayloadAction<string>) => {
      state.market.totalOrder = action.payload;
      return state;
    },

    setStopPriceStopSell: (state, action: PayloadAction<string>) => {
      state.stop.stopPrice = action.payload;
      return state;
    },

    setLimitPriceStopSell: (state, action: PayloadAction<string>) => {
      state.stop.limitPrice = action.payload;
      return state;
    },

    setAmountStopSell: (state, action: PayloadAction<string>) => {
      state.stop.amount = action.payload;
      return state;
    },

    setTotalStopSell: (state, action: PayloadAction<string>) => {
      state.stop.totalOrder = action.payload;
      return state;
    },
  },
});

export const {
  setPriceLimitSell,
  setAmountLimitSell,
  setTotalLimitSell,
  setPriceMarketSell,
  setAmountMarketSell,
  setTotalMarketSell,
  setStopPriceStopSell,
  setLimitPriceStopSell,
  setAmountStopSell,
  setTotalStopSell,
} = sellSlice.actions;
export const sellReducer = sellSlice.reducer;
