import React from "react";
import {
  formatNumberLocale,
  formatNumberLocaleTrim,
} from "../../../utils/stringUtils";
import InputBar from "../../InputBar";
import OrderValuePreset from "../../OrderValuePreset";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { usePair } from "../../../hooks/usePair";
import { useBuy } from "../../../hooks/useBuy";
import { useSell } from "../../../hooks/useSell";
import { toast } from "react-toastify";
import { createStop, ICreateOrderBody } from "../../../services/advanced";

interface IStop {
  buyOrSell?: string;
  style?: string;
}

const Stop = ({ buyOrSell, style }: IStop) => {
  const { t } = useTranslation();

  const formatTo = (value: string, limit: number) => {
    value = value.replaceAll(",", ".");
    let i = value.lastIndexOf(".");

    if (i > 0) {
      let integer = value.substring(0, i).replaceAll(".", "");
      let decimals = value.substring(i + 1, value.length);
      decimals = decimals.substring(0, limit);
      value = integer + "." + decimals;
    }

    return value;
  };

  const { cryptoSymbol } = usePair();
  const { cryptoBalance } = usePair();
  const { assetSymbol } = usePair();
  const { assetBalance } = usePair();

  const [nZero, setNZero] = React.useState(
    cryptoSymbol === "BABYDOGE" ? 12 : 8
  );

  React.useEffect(() => {
    setNZero(cryptoSymbol === "BABYDOGE" ? 12 : 8);
  }, [cryptoSymbol]);

  const { buyStopPrice, onSetStopPriceStopBuy } = useBuy();
  const { buyStopLimitPrice, onSetLimitPriceStopBuy } = useBuy();
  const { buyStopAmount, onSetAmountStopBuy } = useBuy();
  const { buyStopTotal, onSetTotalStopBuy } = useBuy();

  const { sellStopPrice, onSetStopPriceStopSell } = useSell();
  const { sellStopLimitPrice, onSetLimitPriceStopSell } = useSell();
  const { sellStopAmount, onSetAmountStopSell } = useSell();
  const { sellStopTotal, onSetTotalStopSell } = useSell();

  React.useEffect(() => {
    onSetTotalStopBuy(
      (
        parseFloat(formatTo(buyStopLimitPrice, nZero)) *
        parseFloat(formatTo(buyStopAmount, nZero))
      )
        .toFixed(2)
        .toString()
    );
  }, [
    onSetTotalStopBuy,
    buyStopPrice,
    buyStopAmount,
    buyStopLimitPrice,
    nZero,
  ]);

  React.useEffect(() => {
    onSetTotalStopSell(
      (
        parseFloat(formatTo(sellStopLimitPrice, nZero)) *
        parseFloat(formatTo(sellStopAmount, nZero))
      )
        .toFixed(2)
        .toString()
    );
  }, [
    onSetTotalStopSell,
    sellStopPrice,
    sellStopAmount,
    sellStopLimitPrice,
    nZero,
  ]);

  const buyMsg = () => (
    <div className={styles.ctMsg}>
      <p className={styles.msgTitle}>{t("new buy stop order")}</p>
      <p className={styles.msgBody}>
        {t("sent buy stop order")} {buyStopAmount} {cryptoSymbol} {t("using")}{" "}
        {assetSymbol}
      </p>
    </div>
  );

  const sellMsg = () => (
    <div className={styles.ctMsg}>
      <p className={styles.msgTitle}>{t("new sell stop order")}</p>
      <p className={styles.msgBody}>
        {t("sent sell stop order")} {sellStopAmount} {cryptoSymbol} {t("using")}{" "}
        {assetSymbol}
      </p>
    </div>
  );

  const notify = (msg: any) => {
    toast.success(msg, {
      style: { backgroundColor: "rgb(43, 49, 57)" },
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const successHandler = (res: any, type: string) => {
    let data = res.data.data;
    if (data.message) data = data.message;
    console.log(data);
    if (data === "Insuficient balance") {
      notify(t("insufficient balance"));
    } else if (data === "Amount invalid") {
      if (
        cryptoSymbol === "B2U" &&
        Number(sellStopAmount) > 100.0 &&
        buyOrSell === "sell"
      ) {
        notify(t("b2u 100k limite"));
      } else notify(t("invalid value"));
    } else if (data === "Invalid price") {
      notify(t("invalid price"));
    } else if (data === "Invalid above 30 variation") {
      notify(t("invalid price 30"));
    } else if (
      data === "Transação não realizada, tente novamente em alguns segundos"
    ) {
      notify(t("transaction not made"));
    } else if (data === "Total value must be superior to 10BRL") {
      notify(t("superior 10BRL"));
    } else {
      notify(type === "buy" ? buyMsg() : sellMsg());
    }
  };

  const errorHandler = (err: any) => {
    let data = err.response.data
      ? err.response.data.data
        ? err.response.data.data.message
          ? err.response.data.data.message
          : err.response.data.data
        : ""
      : "";
    console.log(data);
    if (data === "Insuficient balance") {
      notify(t("insufficient balance"));
    } else if (data && data.split('#')[0] === "Invalid b2u price") {
      notify(t("b2u 100k limite").toString() + ' ' + data.split('#')[1]);
    } else if (data && data.split('#')[0] === "Invalid b2u amount") {
      notify(t("b2u 100k limite").toString() + ' ' + data.split('#')[1]);
    } else if (data === "Invalid amount") {
      notify(t("invalid value"));
    } else if (data === "Amount invalid") {
      notify(t("invalid value"));
    } else if (data === "Invalid price") {
      notify(t("invalid price"));
    } else if (data === "Invalid above 30 variation") {
      notify(t("invalid price 30"));
    } else if (
      data === "Transação não realizada, tente novamente em alguns segundos"
    ) {
      notify(t("transaction not made"));
    } else if (data === "Total value must be superior to 10BRL") {
      notify(t("superior 10BRL"));
    }
  };

  const confirmBuy = () => {
    let body: ICreateOrderBody = {
      pair: cryptoSymbol + "_" + assetSymbol,
      amount: buyStopAmount,
      side: "BUY",
      price: buyStopLimitPrice,
      stop: buyStopPrice,
    };
    createStop(body)
      .then((res) => {
        successHandler(res, "buy");
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  const confirmSell = () => {
    let body: ICreateOrderBody = {
      pair: cryptoSymbol + "_" + assetSymbol,
      amount: sellStopAmount,
      side: "SELL",
      price: sellStopLimitPrice,
      stop: sellStopPrice,
    };
    createStop(body)
      .then((res) => {
        successHandler(res, "sell");
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  return (
    <div className={`${styles.container} ${style}`}>
      <div
        className={
          buyOrSell === "sell" ? styles.ctInputsHide : styles.ctInputsShow
        }>
        <div className={styles.ctBalance}>
          <p className={styles.balanceText}>{t("available")}</p>
          <p className={styles.balanceValue}>
            {formatNumberLocale(assetBalance, 2) + " " + assetSymbol}
          </p>
        </div>

        <InputBar
          prefix={t("stop price")}
          suffix={assetSymbol}
          input={buyStopPrice}
          setInput={onSetStopPriceStopBuy}
          style={styles.inputField}
        />

        <InputBar
          prefix={t("limit price")}
          suffix={assetSymbol}
          input={buyStopLimitPrice}
          setInput={onSetLimitPriceStopBuy}
          style={styles.inputField}
        />

        <InputBar
          prefix={t("amount")}
          suffix={cryptoSymbol}
          input={buyStopAmount}
          setInput={onSetAmountStopBuy}
          style={styles.inputField}
        />

        <OrderValuePreset
          price={buyStopPrice}
          setAmount={onSetAmountStopBuy}
          wallet={assetBalance}
          buyOrSell="buy"
          style={styles.presetField}
        />

        <InputBar
          prefix={t("total")}
          suffix={assetSymbol}
          input={buyStopTotal}
          disabled={true}
          style={styles.inputField}
        />

        <button className={styles.confirmBuy} onClick={() => confirmBuy()}>
          {t("buy")} {cryptoSymbol}
        </button>
      </div>

      <div
        className={
          buyOrSell === "buy" ? styles.ctInputsHide : styles.ctInputsShow
        }>
        <div className={styles.ctBalance}>
          <p className={styles.balanceText}>{t("available")}</p>
          <p className={styles.balanceValue}>
            {formatNumberLocaleTrim(cryptoBalance, 8) + " " + cryptoSymbol}
          </p>
        </div>

        <InputBar
          prefix={t("stop price")}
          suffix={assetSymbol}
          input={sellStopPrice}
          setInput={onSetStopPriceStopSell}
          style={styles.inputField}
        />

        <InputBar
          prefix={t("limit price")}
          suffix={assetSymbol}
          input={sellStopLimitPrice}
          setInput={onSetLimitPriceStopSell}
          style={styles.inputField}
        />

        <InputBar
          prefix={t("amount")}
          suffix={cryptoSymbol}
          input={sellStopAmount}
          setInput={onSetAmountStopSell}
          style={styles.inputField}
        />

        <OrderValuePreset
          price={sellStopPrice}
          setAmount={onSetAmountStopSell}
          wallet={cryptoBalance}
          buyOrSell="sell"
          style={styles.presetField}
        />

        <InputBar
          prefix={t("total")}
          suffix={assetSymbol}
          input={sellStopTotal}
          disabled={true}
          style={styles.inputField}
        />

        <button className={styles.confirmSell} onClick={() => confirmSell()}>
          {t("sell")} {cryptoSymbol}
        </button>
      </div>
    </div>
  );
};

export default Stop;
