import { useDispatch, useSelector } from "react-redux";
import {
  setPriceLimitBuy,
  setAmountLimitBuy,
  setTotalLimitBuy,
  setPriceMarketBuy,
  setAmountMarketBuy,
  setTotalMarketBuy,
  setStopPriceStopBuy,
  setLimitPriceStopBuy,
  setAmountStopBuy,
  setTotalStopBuy,
} from "../state/features/Buy/buy.slice";
import { RootState } from "../state/store";

export const useBuy = () => {
  const dispatch = useDispatch();

  const buyLimitPrice = useSelector(
    (state: RootState) => state.Buy.limit.price
  );

  const buyLimitAmount = useSelector(
    (state: RootState) => state.Buy.limit.amount
  );

  const buyLimitTotal = useSelector(
    (state: RootState) => state.Buy.limit.totalOrder
  );

  const buyMarketPrice = useSelector(
    (state: RootState) => state.Buy.market.price
  );

  const buyMarketAmount = useSelector(
    (state: RootState) => state.Buy.market.amount
  );

  const buyMarketTotal = useSelector(
    (state: RootState) => state.Buy.market.totalOrder
  );

  const buyStopPrice = useSelector(
    (state: RootState) => state.Buy.stop.stopPrice
  );

  const buyStopLimitPrice = useSelector(
    (state: RootState) => state.Buy.stop.limitPrice
  );

  const buyStopAmount = useSelector(
    (state: RootState) => state.Buy.stop.amount
  );

  const buyStopTotal = useSelector(
    (state: RootState) => state.Buy.stop.totalOrder
  );

  const onSetPriceLimitBuy = (value: string) => {
    dispatch(setPriceLimitBuy(value));
  };

  const onSetAmountLimitBuy = (value: string) => {
    dispatch(setAmountLimitBuy(value));
  };

  const onSetTotalLimitBuy = (value: string) => {
    dispatch(setTotalLimitBuy(value));
  };

  const onSetPriceMarketBuy = (value: string) => {
    dispatch(setPriceMarketBuy(value));
  };

  const onSetAmountMarketBuy = (value: string) => {
    dispatch(setAmountMarketBuy(value));
  };

  const onSetTotalMarketBuy = (value: string) => {
    dispatch(setTotalMarketBuy(value));
  };

  const onSetStopPriceStopBuy = (value: string) => {
    dispatch(setStopPriceStopBuy(value));
  };

  const onSetLimitPriceStopBuy = (value: string) => {
    dispatch(setLimitPriceStopBuy(value));
  };

  const onSetAmountStopBuy = (value: string) => {
    dispatch(setAmountStopBuy(value));
  };

  const onSetTotalStopBuy = (value: string) => {
    dispatch(setTotalStopBuy(value));
  };

  return {
    onSetPriceLimitBuy,
    onSetAmountLimitBuy,
    onSetTotalLimitBuy,
    onSetPriceMarketBuy,
    onSetAmountMarketBuy,
    onSetTotalMarketBuy,
    onSetStopPriceStopBuy,
    onSetLimitPriceStopBuy,
    onSetAmountStopBuy,
    onSetTotalStopBuy,
    buyLimitPrice,
    buyLimitAmount,
    buyLimitTotal,
    buyMarketPrice,
    buyMarketAmount,
    buyMarketTotal,
    buyStopPrice,
    buyStopLimitPrice,
    buyStopAmount,
    buyStopTotal,
  };
};
