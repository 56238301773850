import React, { useEffect } from "react";
import {
  formatNumberLocale,
  formatNumberLocaleTrim,
} from "../../utils/stringUtils";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { usePair } from "../../hooks/usePair";
import { useBuy } from "../../hooks/useBuy";
import { useSell } from "../../hooks/useSell";
import { IBookData } from "../../state/features/Book/book.slice";
import BigNumber from "bignumber.js";
import { useBook } from "../../hooks/useBook";

interface IOrderBook {
  buyData: IBookData[];
  sellData: IBookData[];
  style?: string;
}

// interface BookData {
//   type: string;
//   price: string;
//   amount: string;
//   total: string;
// }

const OrderBook = ({ buyData, sellData, style }: IOrderBook) => {
  const { t } = useTranslation();

  const { cryptoSymbol } = usePair();
  const { assetSymbol } = usePair();
  const { bidBook, askBook } = useBook();
  // useEffect(() => {
  sellData = askBook;
  buyData = bidBook;
  // }, [getAskBook(), getBidBook()])

  const [spread, setSpread] = React.useState("0.0 (0.00%)");

  const { onSetPriceLimitBuy } = useBuy();
  const { onSetAmountLimitBuy } = useBuy();
  const { onSetLimitPriceStopBuy } = useBuy();
  const { onSetAmountStopBuy } = useBuy();

  const { onSetPriceLimitSell } = useSell();
  const { onSetAmountLimitSell } = useSell();
  const { onSetLimitPriceStopSell } = useSell();
  const { onSetAmountStopSell } = useSell();

  const autoComplete = (entry: IBookData, type: string) => {
    if (type === "sell") {
      onSetPriceLimitBuy(entry.price.toString());
      onSetAmountLimitBuy(entry.amount.toString());
      onSetLimitPriceStopBuy(entry.price.toString());
      onSetAmountStopBuy(entry.amount.toString());
    }

    if (type === "buy") {
      onSetPriceLimitSell(entry.price.toString());
      onSetAmountLimitSell(entry.amount.toString());
      onSetLimitPriceStopSell(entry.price.toString());
      onSetAmountStopSell(entry.amount.toString());
    }
  };

  useEffect(() => {
    if (askBook && askBook.length > 0 && bidBook && bidBook.length > 0) {
      let value = new BigNumber(askBook[askBook.length - 1].price)
        .minus(bidBook[0].price)
        .toFixed(4);
      let percent = new BigNumber(value)
        .dividedBy(askBook[askBook.length - 1].price)
        .multipliedBy(100)
        .toFixed(8);
      console.log(value, percent);
      setSpread(`${value} (${percent}%)`);
    } else {
      setSpread(`0.0 (0.00%)`);
    }
  }, [askBook, bidBook]);

  const [isHovering, setIsHovering] = React.useState(false);
  const bottomRef = React.useRef<null | HTMLDivElement>(null);
  const ctAskBookRef = React.useRef<null | HTMLDivElement>(null);

  React.useEffect(() => {
    if (!isHovering)
      console.log("ANALISANDO ===> ", ctAskBookRef.current?.clientHeight);
    ctAskBookRef.current?.scroll({
      behavior: "smooth",
      top: 100,
    });
    // bottomRef.current?.scrollIntoView({
    //   behavior: "smooth",
    //   block: "end"
    // });
  }, [askBook, bidBook, isHovering]);

  return (
    <div className={`${styles.container} ${style}`}>
      <h3 className={styles.title}>{t("order book")}</h3>

      <div className={styles.ctLabels}>
        <h3 className={styles.colLabelLeft}>
          {t("price")} ({assetSymbol})
        </h3>

        <h3 className={styles.colLabelRight}>
          {t("amount")} ({cryptoSymbol})
        </h3>

        <h3 className={styles.colLabelRight}>{t("total")}</h3>
      </div>

      <div
        ref={ctAskBookRef}
        className={styles.ctTradesSell}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className={styles.ctCol}>
          {sellData.map((entry) => {
            return (
              <p className={styles.bookItemText}>
                {formatNumberLocale(
                  new BigNumber(entry.amount)
                    .multipliedBy(entry.price)
                    .toFixed(8),
                  2
                )}
              </p>
            );
          })}
        </div>

        <div className={styles.ctCol}>
          {sellData.map((entry) => {
            return (
              <p
                className={styles.bookItemText}
                onClick={() => autoComplete(entry, "sell")}
              >
                {cryptoSymbol == "BABYDOGE"
                  ? entry.amountWithFormatter
                  : formatNumberLocale(entry.amount, 8)}
              </p>
            );
          })}
        </div>

        <div className={styles.ctCol}>
          {sellData.map((entry) => {
            return (
              <p
                className={styles.bookItemSell}
                onClick={() => autoComplete(entry, "sell")}
              >
                {entry.price}
              </p>
            );
          })}
          <div ref={bottomRef} />
        </div>
      </div>

      <h2 className={styles.spread}>{t("spread") + ": " + spread}</h2>

      <div className={styles.ctTradesBuy}>
        <div className={styles.ctCol}>
          {buyData.map((entry) => {
            return (
              <p
                className={styles.bookItemBuy}
                onClick={() => autoComplete(entry, "buy")}
              >
                {entry.price}
              </p>
            );
          })}
        </div>

        <div className={styles.ctCol}>
          {buyData.map((entry) => {
            return (
              <p
                className={styles.bookItemText}
                onClick={() => autoComplete(entry, "buy")}
              >
                {cryptoSymbol == "BABYDOGE"
                  ? entry.amountWithFormatter
                  : formatNumberLocale(entry.amount, 8)}
              </p>
            );
          })}
        </div>

        <div className={styles.ctCol}>
          {buyData.map((entry) => {
            return (
              <p className={styles.bookItemText}>
                {formatNumberLocale(
                  new BigNumber(entry.amount)
                    .multipliedBy(entry.price)
                    .toFixed(8),
                  2
                )}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OrderBook;
