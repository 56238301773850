import { useDispatch, useSelector } from "react-redux";
import {
  setExecutedOrders,
  setOpenOrders,
  IUserOrderData,
} from "../state/features/UserOrder/userOrder.slice";
import { RootState } from "../state/store";

export const useUserOrders = () => {
  const dispatch = useDispatch();

  const openOrders = useSelector(
    (state: RootState) => state.UserOrder.openOrders
  );

  const executedOrders = useSelector(
    (state: RootState) => state.UserOrder.executedOrders
  );

  const tradeOrders = useSelector(
    (state: RootState) => state.UserOrder.tradeOrders
  );

  const onsetOpenOrders = (value: IUserOrderData[]) => {
    dispatch(setOpenOrders(value));
  };

  const onSetExecutedOrders = (value: IUserOrderData[]) => {
    dispatch(setExecutedOrders(value));
  };

  return {
    openOrders,
    executedOrders,
    tradeOrders,
    onsetOpenOrders,
    onSetExecutedOrders,
  };
};
