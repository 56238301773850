import { HistoryCallback, IBasicDataFeed, IDatafeedChartApi, ErrorCallback, IDatafeedQuotesApi, IExternalDatafeed, LibrarySymbolInfo, PeriodParams, ResolutionString, Bar, SubscribeBarsCallback } from '../charting_library/charting_library';
import { clientHistory } from './clientHistory';
import { stream } from './stream';

const supportedResolutions = ["1", "3", "5", "15", "30", "60", "120", "240", "360", "480", "720", "1440", "3D", "W", "M"];

const config = {
    supported_resolutions: supportedResolutions
};

// @Injectable({
//     providedIn: 'root'
// })

// export class TV {
//     constructor(
//         private http: HttpClient,
//         private historyProvider: HistoryProvider,
//         private stream: StreamProvider
//     ) { }

// getDF(): any {
//     return this;
// }


function onReady(cb: any) {
    // console.log('=====onReady running')
    setTimeout(() => cb(config), 0)
}

function searchSymbols(userInput: any, exchange: any, symbolType: any, onResultReadyCallback: any) {
    // console.log('====Search Symbols running')
}

function resolveSymbol(symbolName: any, onSymbolResolvedCallback: any, onResolveErrorCallback: any) {
    // expects a symbolInfo object in response
    // console.log('======resolveSymbol running')
    // console.log('resolveSymbol:',{symbolName})
    var split_data = symbolName.split(/[:_]/)
    // console.log({split_data})
    var symbol_stub = {
        name: symbolName,
        description: '',
        type: 'crypto',
        session: '24x7',
        timezone: 'Etc/UTC',
        ticker: symbolName,
        exchange: 'Bitcointoyou',
        minmov: 1,
        // pricescale: 100000000,
        pricescale: 100,
        has_intraday: true,
        intraday_multipliers: ["1"],
        supported_resolution: supportedResolutions,
        volume_precision: 8,
        data_status: 'streaming',
    }

    if (split_data[1].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
        symbol_stub.pricescale = 100
    }
    setTimeout(function () {
        onSymbolResolvedCallback(symbol_stub)
        // console.log('Resolving that symbol....', symbol_stub)
    }, 0)
    // onResolveErrorCallback('Not feeling it today')
}
function getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, onHistoryCallback: HistoryCallback, onErrorCallback: ErrorCallback) {
    // console.log('=====getBars running')
    // console.log('function args',arguments)
    // console.log(`Requesting bars between ${new Date(from * 1000).toISOString()} and ${new Date(to * 1000).toISOString()}`)
    clientHistory.getBars(symbolInfo, resolution, periodParams.from, periodParams.to)
        .then((bars: Bar[] | undefined) => {
            let barsF = bars || [];
            console.log('chegou aqui')
            if (barsF && barsF.length > 0) {
                onHistoryCallback(barsF, { noData: false })
            } else {
                onHistoryCallback(barsF, { noData: true })
            }
        }).catch(err => {
            console.log({ err })
            onErrorCallback(err)
        })

}
function subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void) {
    // console.log('=====subscribeBars runnning')
    stream.subscribeBars(symbolInfo, resolution,  onTick, listenerGuid, onResetCacheNeededCallback)
}

function unsubscribeBars(listenerGuid: string) {
    // console.log('=====unsubscribeBars running')

    stream.unsubscribeBars(listenerGuid)
}
// calculateHistoryDepth(resolution, resolutionBack, intervalBack) {
//     // optional
//     console.log('=====calculateHistoryDepth running', resolutionBack, intervalBack, resolution, resolution < 30);
//     // while optional, this makes sure we request 24 hours of minute data at a time
//     // CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
//     resolution =  Number(resolution)
//     return resolution < 30 ? { resolutionBack: 'M', intervalBack: 1 } : undefined
// }
function getMarks(symbolInfo: any, startDate: any, endDate: any, onDataCallback: any, resolution: any) {
    //optional
    // console.log('=====getMarks running')
}
function getTimeScaleMarks(symbolInfo: any, startDate: any, endDate: any, onDataCallback: any, resolution: any) {
    //optional
    // console.log('=====getTimeScaleMarks running')
}
function getServerTime(cb: any) {
    // console.log('=====getServerTime running')
}

export var dataFeed: IBasicDataFeed | (IDatafeedChartApi & IExternalDatafeed & IDatafeedQuotesApi) = {
    getBars,
    getMarks,
    getServerTime,
    // getTimeScaleMarks,
    subscribeBars,
    unsubscribeBars,
    resolveSymbol,
    searchSymbols,
    onReady
}

