import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { loadingReducer } from "./features/Loading/loading.slice";
import { infoReducer } from "./features/Info/info.slice";
import { pairReducer } from "./features/Pair/pair.slice";
import { buyReducer } from "./features/Buy/buy.slice";
import { sellReducer } from "./features/Sell/sell.slice";
import { bookReducer } from "./features/Book/book.slice";
import { userReducer } from "./features/User/user.slice";
import { tradeReducer } from "./features/Trade/trade.slice";
import { userOrdersReducer } from "./features/UserOrder/userOrder.slice";
import { summaryReducer } from "./features/Summary/summary.slice";
import { allSummaryReducer } from "./features/AllSummary/allsummary.slice";

const persistConfig = {
  key: "root",
  storage: storage,
};

const reducers = combineReducers({
  Loading: loadingReducer,
  Info: infoReducer,
  Pair: pairReducer,
  Buy: buyReducer,
  Sell: sellReducer,
  Book: bookReducer,
  User: userReducer,
  Trade: tradeReducer,
  UserOrder: userOrdersReducer,
  Summary: summaryReducer,
  AllSummary: allSummaryReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
