import React from "react";
import { formatNumberLocale, getPriceDecimalsPlaces } from "../../utils/stringUtils";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { usePair } from "../../hooks/usePair";
import { useBuy } from "../../hooks/useBuy";
import { useSell } from "../../hooks/useSell";
import { useBook } from "../../hooks/useBook";
import { IBookData } from "../../state/features/Book/book.slice";

interface IOrderBookMobile {
  buyData: IBookData[];
  sellData: IBookData[];
  style?: string;
}

interface TradesData {
  type: string;
  price: string;
  amount: string;
  total: string;
}

const OrderBookMobile = ({ buyData, sellData, style }: IOrderBookMobile) => {
  const { t } = useTranslation();

  const { cryptoSymbol } = usePair();
  const { assetSymbol } = usePair();
  const { askBook, bidBook } = useBook();
  buyData = bidBook;
  sellData = [...askBook].reverse();
  const { onSetPriceLimitBuy } = useBuy();
  const { onSetAmountLimitBuy } = useBuy();
  const { onSetLimitPriceStopBuy } = useBuy();
  const { onSetAmountStopBuy } = useBuy();

  const { onSetPriceLimitSell } = useSell();
  const { onSetAmountLimitSell } = useSell();
  const { onSetLimitPriceStopSell } = useSell();
  const { onSetAmountStopSell } = useSell();

  const autoComplete = (entry: IBookData, type: string) => {
    if (type === "buy") {
      onSetPriceLimitBuy(entry.price.toString());
      onSetAmountLimitBuy(entry.amount.toString());
      onSetLimitPriceStopBuy(entry.price.toString());
      onSetAmountStopBuy(entry.amount.toString());
    }

    if (type === "sell") {
      onSetPriceLimitSell(entry.price.toString());
      onSetAmountLimitSell(entry.amount.toString());
      onSetLimitPriceStopSell(entry.price.toString());
      onSetAmountStopSell(entry.amount.toString());
    }
  };

  return (
    <div className={`${styles.container} ${style}`}>
      <div className={styles.ctCol}>
        <div className={styles.ctLabels}>
          <h3 className={styles.colLabelLeft}>{t("amount")}</h3>

          <h3 className={styles.colLabelRight}>{t("price")}</h3>
        </div>

        <div className={styles.ctTradesSell}>
          <div className={styles.ctSell}>
            <div className={styles.ctCol}>
              {sellData.map((entry) => {
                return (
                  <p
                    className={styles.bookItemTextLeft}
                    onClick={() => autoComplete(entry, "sell")}>
                    {cryptoSymbol == 'BABYDOGE' ? entry.amountWithFormatter : formatNumberLocale(entry.amount, 8)}
                  </p>
                );
              })}
            </div>

            <div className={styles.ctCol}>
              {sellData.map((entry) => {
                return (
                  <p
                    className={styles.bookItemSell}
                    onClick={() => autoComplete(entry, "sell")}>
                    {formatNumberLocale(entry.price, getPriceDecimalsPlaces(cryptoSymbol + '_' + assetSymbol))}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.ctCol}>
        <div className={styles.ctLabels}>
          <h3 className={styles.colLabelLeft}>{t("price")}</h3>

          <h3 className={styles.colLabelRight}>{t("amount")}</h3>
        </div>

        <div className={styles.ctTrades}>
          <div className={styles.ctCol}>
            {buyData.map((entry) => {
              return (
                <p
                  className={styles.bookItemBuy}
                  onClick={() => autoComplete(entry, "buy")}>
                  {formatNumberLocale(entry.price, getPriceDecimalsPlaces(cryptoSymbol + '_' + assetSymbol))}
                </p>
              );
            })}
          </div>

          <div className={styles.ctCol}>
            {buyData.map((entry) => {
              return (
                <p
                  className={styles.bookItemTextRight}
                  onClick={() => autoComplete(entry, "buy")}>
                  {cryptoSymbol == 'BABYDOGE' ? entry.amountWithFormatter : formatNumberLocale(entry.amount, 8)}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderBookMobile;
